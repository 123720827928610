<!--
tabValue.sync 选项卡选中值  Number
options tab所需数据  Array  格式：[{label:'选项卡1',value:1}]
@confirm 传出 tabValue

线上客Saas组件库 选项卡 --- 2021.03.17  
 -->

<template>
  <div id="xskTabs">
    <div class="xskTabs" :style="{'boderBottom':'2px solid #e9edef'}">
      <div class="tab" :style="tabStyle" :class="tabValue===item.value?'active':''" v-for="(item,index) in options" :key="index">
        <span @click="changetype(item.value)">{{item.label}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'xskTabs',
  data() {
    return {

    };
  },
  props: {
    border: {
      type: Boolean,
      default: true
    },
    //选项卡选中值
    tabValue: {
      type: [Number, String],
      default: 0,
    },
    //Tab数据 
    options: {
      type: Array,
      default: () => [],
    },
    tabStyle: {
      type: String,
      default: ''
    }
  },
  methods: {
    changetype(value) {
      this.$emit('update:tabValue', value)
      this.$emit('confirm', value)
    },
  }
};
</script>

<style scoped lang="less">
#xskTabs {
  .xskTabs {
    background: #ffffff;
    // border-bottom: 2px solid #e9edef;
    display: flex;
    .tab {
      padding: 0 0 12px;
      font-size: 18px;
      font-weight: bold;
      line-height: 25px;
      margin-right: 40px;
      span {
        cursor: pointer;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    .active {
      color: #1e92ff;
      border-bottom: 4px solid #1e92ff;
    }
  }
}
</style>
