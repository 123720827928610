<!--
managehide.sync 控制插件显示 Boolean
multiple 是否多选 Boolean 默认 false
count 上传数量限制 Number 仅在 multiple为true时生效 默认9
@confirm 父组件获取回传信息 

插件依赖 element-ui
线上客Saas组件库 图片管理器组件 --- 2020.09.24  
 -->
<template>
  <div id="xsk-manage">
    <el-dialog title="添加图片" center :visible.sync="managehide" width="990px" :modal="false" :close-on-click-modal="false" :show-close="false">
      <div class="uploadsbox" v-loading="loading">
        <div class="leftbox">
          <el-popover placement="top" width="220" v-model="visible">
            <div style="font-weight: bold;margin-bottom: 10px;">添加分组</div>
            <el-input v-model="classname" placeholder="请输入分组名称"></el-input>

            <div style="text-align: right; margin: 10px 0 0">
              <el-button type="infor" size="small" plain @click="visible = false">取消</el-button>
              <el-button type="primary" size="small" @click="addclass">确定</el-button>
            </div>
            <el-button class="addbtn" size="small" slot="reference" type="primary" plain>+添加分组</el-button>
          </el-popover>
          <div class="classbox">
            <div class="textbox" v-for="(item, index) in classlist" :key="index" :class="{ active: item.id === active }" @mouseenter="classhover(item.id)" @mouseleave="classleave()" @click="pickclass(item.id)">
              <div class="name">{{ item.name }}</div>
              <div class="number">
                <div v-show="hover_id != item.id">{{ item.total }}</div>
                <div v-show="hover_id == item.id">
                  <!-- <span>编辑</span>
									<span style="margin: 0 4px;">|</span> -->
                  <span @click="delclass(item.id)">删除</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="rightbox">
          <div class="headbox">
            <div class="btn">
              <el-button class="addbtn" size="small" type="primary">+添加图片</el-button>
              <input class="addinput" id="uploadImg" ref="uploadImg" accept="image/*" type="file" :multiple="true" @change="uploadimg" />
              <!-- <span>大小不要超过1M</span> -->
            </div>
            <div class="btn">
              <el-button class="addbtn" size="small" type="primary" @click="getWatermarkSetting()">水印设置</el-button>
              <!-- <input class="addinput" id="uploadImg" ref="uploadImg" accept="image/*" type="file" :multiple="true" @change="uploadimg"/> -->
            </div>
            <!-- <div class="search">
							<el-date-picker v-model="date" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
						</div> -->
          </div>
          <div class="imglist">
            <div class="nodata" v-show="images.length == 0">
              <i class="el-icon-document-copy" style="font-size: 80px;color: #939799;"></i>
              <div class="tips">
                暂无数据，
                <span>去添加</span>
                <input class="input" id="uploadImg2" ref="uploadImg2" accept="image/*" type="file" :multiple="true" @change="uploadimg" />
              </div>
            </div>
            <div class="imgbox">
              <div class="imgs" v-for="(item, index) in images" :key="index" @click="pickimg(item)" @mouseenter="hoverimg(item.id)" @mouseleave="leaveimg()">
                <el-image class="image" :src="item.qiniu_url" fit="cover"></el-image>
                <div class="meng" v-if="selectimg.indexOf(item.id) != -1"><i class="el-icon-success" style="font-size: 24px;color: #1989fa;"></i></div>
                <i class="el-icon-error closeicon" v-if="imghover_id == item.id && selectimg.indexOf(item.id) == -1" @click.stop="delimg(item.id,index)"></i>
                <!-- <div class="sizebox" v-if="imghover_id == item.id && selectimg.indexOf(item.id) == -1">{{item.width}} x {{item.height}}</div> -->
                <div class="text">{{item.original_name}}</div>
              </div>
            </div>
            <div class="btnbox" v-if="images.length>0">
              <el-button size="small" plain :disabled="selectimg.length==0" @click="delimgmore">
                删除选中
                <span v-if="selectimg.length > 0">{{ selectimg.length }}</span>
              </el-button>

              <el-pagination background @current-change="chagepage" :current-page="page" :small="true" :page-size="10" layout="total, prev, pager, next,jumper" :total="total"></el-pagination>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer">
        <el-button size="medium" type="primary" plain @click="hidemanage">取 消</el-button>
        <el-button size="medium" type="primary" @click="confirmimg">确 定</el-button>
      </span>

      <el-dialog width="600px" title="水印设置" :visible.sync="watermarkShow" :close-on-click-modal="false" :show-close="false" center append-to-body>
        <div class="watermarkbox" v-loading="watermarkLoading">
          <el-form label-width="80px">
            <el-form-item label="添加水印">
              <el-radio v-model="watermarkForm.status" :label="0">关闭</el-radio>
              <el-radio v-model="watermarkForm.status" :label="1">开启</el-radio>
            </el-form-item>
            <el-form-item label="水印样式">
              <el-radio v-model="watermarkForm.type" :label="2">图片水印</el-radio>
              <el-radio v-model="watermarkForm.type" disabled :label="1">文字水印</el-radio>
            </el-form-item>
            <el-form-item label="水印内容">
              <el-input v-if="watermarkForm.type==1" v-model="watermarkForm.text" placeholder="请输入内容"></el-input>
              <div v-else>
                <div class="imgbox">
                  <input class="inputbtn" id="inputbtn" ref="inputbtn" accept="image/*" type="file" :multiple="false" @change="pickWatermark" />
                  <el-image class="img" v-if="watermarkForm.image" :src="watermarkForm.image" fit="cover"></el-image>
                  <i v-if="!watermarkForm.image" class="el-icon-plus addicon"></i>
                  <div v-if="!watermarkForm.image" class="text">添加图片</div>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>

        <span slot="footer">
          <el-button size="medium" type="primary" plain @click="watermarkShow=false">取消</el-button>
          <el-button size="medium" type="primary" @click="saveWatermarkSetting">保存</el-button>
        </span>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import * as qiniu from 'qiniu-js';
import { eltips } from '@/util/util.js';
export default {
  name: 'manage',
  data() {
    return {
      loading: false,
      date: '',
      classlist: [],
      images: [],
      active: '',
      hover_id: -2,
      selectimg: [],
      selectobj: [],
      imghover_id: '',
      page: 1,
      total: 0,
      visible: false,
      classname: '',
      start_time: '',
      end_time: '',
      watermarkShow: false,
      watermarkForm: {
        status: 0,//水印状态【0关闭，1开启】
        type: 2, //水印类型【1文字，2图片】
        image: '',
        text: '',
        urlsafe: '',
      },
      watermarkLoading: false,
      watermarkSetting: '',
    };
  },
  props: {
    //控制插件显示 
    managehide: {
      type: Boolean,
      default: false
    },
    //是否多选
    multiple: {
      type: Boolean,
      default: false
    },
    //选择数量限制
    count: {
      type: Number,
      default: 9
    },
    //回显示id数组
    selectarr: {
      type: Array,
      default: () => [],
    },
    //回显示ojb数组
    selectobjs: {
      type: Array,
      default: () => [],
    },
    //打开前是否重置选中
    is_rest: {
      type: Boolean,
      default: false
    },

  },
  watch: {
    //插件显示监听 显示时禁止页面滚动
    managehide(val) {
      let preD = function (e) {
        e.preventDefault();
      };
      if (val) {
        if (this.is_rest) {
          this.selectimg = []
          this.selectobj = []
        }
        document.body.style.overflow = 'hidden';
        document.addEventListener('touchmove', preD, { passive: false });
      } else {
        document.body.style.overflow = ''; // 出现滚动条
        document.removeEventListener('touchmove', preD, { passive: false });
      }
    },
    selectarr(val) {
      this.selectimg = JSON.parse(JSON.stringify(val))
    },
    selectobjs(val) {
      this.selectobj = JSON.parse(JSON.stringify(val))
    }
  },
  created() {
    this.getclasslist()
    this.getWatermarkSetting('cover')
  },
  methods: {
    getWatermarkSetting(cover) {
      this.loading = true
      this.axios.get('/api/image/watermark_settings').then(res => {
        this.loading = false
        if (res.code == 200) {
          if (cover) {
            return this.watermarkSetting = res.data
          }

          if (res.data) {
            this.watermarkForm = {
              status: res.data.status,
              type: res.data.type,
              image: '',
              text: '',
              urlsafe: res.data.urlsafe
            }
            if (res.data.type == 1) {
              this.watermarkForm.text = res.data.content
              this.watermarkForm.image = ''
            } else {
              this.watermarkForm.image = res.data.content
              this.watermarkForm.text = ''
            }
          }
          this.watermarkShow = true
        } else {
          eltips(res.msg, 'error')
        }
      })
    },
    //保存水印设置
    saveWatermarkSetting() {
      if (this.watermarkForm.type == 1 && !this.watermarkForm.text) {
        return eltips('请输入水印内容', 'error')
      }

      if (this.watermarkForm.type == 2 && !this.watermarkForm.image) {
        return eltips('请上传水印图片', 'error')
      }

      let data = {
        status: this.watermarkForm.status,
        type: this.watermarkForm.type,
        urlsafe: this.watermarkForm.urlsafe
      }
      if (this.watermarkForm.type == 1) {
        data.content = this.watermarkForm.text
      } else {
        data.content = this.watermarkForm.image
      }

      this.watermarkLoading = true
      this.axios.post('/api/image/watermark_settings', data).then(res => {
        this.watermarkLoading = false
        if (res.code == 200) {
          this.watermarkSetting = data
          eltips(res.msg, 'success')
        } else {
          eltips(res.msg, 'error')
        }
      })
    },
    //关闭组件
    hidemanage() {
      this.$emit('update:managehide', false)
    },
    //页码改变
    chagepage(e) {
      this.page = e
      this.getimgslist()
    },
	// 上传图片 新版本
	async uploadimg(e){
		let that = this;
		let imgs = e.target.files
		let len = imgs.length;
		let flag = true;
		let group_id = this.active
		if (group_id == -1 || group_id == 0) {
			group_id = false
		}
		if (imgs.length > 10) {
			return eltips('最多上传10张图片', 'error')
		}
		
		let successarr = []
		this.loading = true
		let timer = setInterval(()=>{
			if(len && flag){
				flag = false;
				//设置图片key 时间戳+用户id+unitid+文件名
				let file = e.target.files[len-1];
				let userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
				let key = new Date().getTime() +userinfo.id+userinfo.unitid + file.name;
				let token;
				this.watermarkLoading = true
				//获取七牛token
				this.axios.post('/api/upload/get_qiniu_token').then(res1 => {
					if(res1.code==200){
						token = res1.data;
						//设置七牛云参数 config
						let config = {
							concurrentRequestLimit: 2
						};
						
						//设置七牛云参数 putExtra
						let putExtra = {
							//原文件名
							fname: '',
							//用来放置自定义变量
							params: {},
							//限制上传文件类型
							mimeType: null
						};
						//设置七牛云上传回调事件 observer
						let observer = {
							//上传失败回调
							error:(err)=> {
								that.watermarkLoading = false
								that.$refs['inputbtn'].value = '' 
								eltips('上传七牛云失败，请重试','error')
							},
							// 上传完成回调
							complete:(res)=> {
								that.watermarkLoading = false
								let kodo = 'https://qiniuyun.xcooteam.cn/'+res.key
								that.watermarkForm.image = 'https://qiniuyun.xcooteam.cn/'+res.key
								that.watermarkForm.urlsafe = '?watermark/1/image/'+BASE64.urlsafe_encode(kodo)+'/gravity/Center/ws/0.3'
								this.axios.post('/api/upload/qiniu_upload_img', {
									qiniu_url:kodo,
									file_name:res.key
								}).then(res => {
									if (res.code == 200) {
										len--;
										flag = true;
										successarr.push(1)
									} else {
										eltips(res.msg, 'error')
									}
								})
							}
						};
						//创建七牛云上传
						let observable = qiniu.upload(file, key, token, putExtra, config);
						//触发上传并执行上传回调
						observable.subscribe(observer)
					}else{
						this.watermarkLoading = false
						eltips('获取七牛云Token失败，请重试','error')
					}
				});
			} else {
				if(!len){
					clearInterval(timer);
					this.loading = false
					this.page = 1
					this.$refs['uploadImg'].value = ''
					this.$refs['uploadImg2'].value = ''
					eltips('上传成功', 'success')
					this.getclasslist()
				}
			}
		},10)
	},
    //上传图片 老版本
    // async uploadimg(e) {
    //   let imgs = e.target.files
    //   let group_id = this.active
    //   if (group_id == -1 || group_id == 0) {
    //     group_id = false
    //   }
    //   if (imgs.length > 10) {
    //     return eltips('最多上传10张图片', 'error')
    //   }

    //   let successarr = []
    //   this.loading = true
    //   for (let i = 0; i < imgs.length; i++) {
    //     let formData = new FormData();
    //     formData.append('file', imgs[i]);
    //     if (group_id) {
    //       formData.append('group_id', group_id);
    //     }
    //     this.axios.post('/api/upload/qiniu_upload_img', formData).then(res => {
    //       if (res.code == 200) {
    //         successarr.push(1)
    //       } else {
    //         eltips(res.msg, 'error')
    //         this.loading = false
    //       }

    //       if (imgs.length == successarr.length) {
    //         this.page = 1
    //         this.$refs['uploadImg'].value = ''
    //         this.$refs['uploadImg2'].value = ''
    //         eltips('上传成功', 'success')
    //         this.getclasslist()
    //       }
    //     })
    //   }

    // },
    //删除图片
    delimg(id) {
      this.$confirm('此操作将永久删除该图片, 是否继续?', '删除图片', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.delete('/api/upload/manage_img_del/' + id).then(res => {
          if (res.code == 200) {
            if (this.selectimg.indexOf(id) != -1) {
              this.selectimg.splice(this.selectimg.indexOf(id), 1)
              this.selectobj.splice(this.selectimg.indexOf(id), 1)
            }

            eltips(res.msg, 'success')
            this.getclasslist()
          } else {
            eltips(res.msg, 'error')
          }
        })
      }).catch(() => {
        console.log('取消删除')
      });
    },
    //多选删除
    delimgmore() {
      this.$confirm('此操作将永久删除选中的所有图片, 是否继续?', '删除图片', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let ids = this.selectimg.join()
        this.axios.delete('/api/upload/manage_img_del/' + ids).then(res => {
          this.selectobj = []
          if (res.code == 200) {
            eltips(res.msg, 'success')
            this.getclasslist()
          } else {
            eltips(res.msg, 'error')
          }
        }).catch()
      }).catch(() => {
        console.log('取消删除')
      });
    },
    //模拟hove事件
    classhover(id) {
      if (id != -1 && id != 0) {
        this.hover_id = id;
      }
    },
    classleave() {
      this.hover_id = -2;
    },
    //添加分组
    addclass() {
      if (!this.classname) {
        eltips('请输入分组名称', 'error')
        return false
      }

      this.axios.post('/api/upload/create_group', { name: this.classname }).then(res => {
        if (res.code == 200) {
          this.getclasslist()
          eltips(res.msg, 'success')
          this.visible = false
        } else {
          eltips(res.msg, 'error')
        }
      }).catch(err => {
        console.log(err)
      })
    },
    //删除分组
    delclass(id) {
      this.$confirm('此操作将永久删除该分组, 是否继续?', '删除分组', { type: 'warning' }).then(() => {
        this.axios.delete('/api/upload/del_group/' + id).then(res => {
          if (res.code == 200) {
            eltips(res.msg, 'success')
            this.page = 1
            this.getclasslist()
          } else {
            eltips(res.msg, 'error')
          }
        }).catch()
      }).catch();
    },
    //获取分组列表
    getclasslist() {
      let that = this;
      this.axios.get('/api/upload/img_group').then(res => {
        if (res.code == 200) {
          let arr = []
          res.data[0].forEach(item => {
            if (item.id === '') {
              item.id = -1
            }
            arr.push(item)
          })

          res.data.splice(0, 1)

          arr = arr.concat(res.data)
          this.classlist = arr

          if (!this.active) {
            that.active = arr[0].id
          } else {
            let is_have = false
            arr.forEach(item => {
              if (item.id == that.active) {
                is_have = true
              }
            })
            if (!is_have) {
              that.active = arr[0].id
            }
          }

          this.getimgslist()
        } else {
          eltips(res.msg, 'error')
        }
      }).catch(err => {
        console.log(err)
      })
    },
    //获取图片列表
    getimgslist() {
      let group_id = this.active
      this.loading = true
      if (group_id == -1) {
        group_id = ''
      }
      let data = {
        group_id: group_id,
        limit: 10,
        page: this.page
      }
      if (this.start_time) {
        data.start_time = this.start_time + ' 00:00:00'
      }
      if (this.end_time) {
        data.end_time = this.end_time + ' 23:59:59'
      }

      this.axios.get('/api/upload/manage_img_list', {
        params: data
      }).then(res => {
        this.loading = false
        if (res.code == 200) {
          this.total = res.data.total
          this.images = res.data.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    //选择分组
    pickclass(id) {
      if (id != this.active) {
        this.active = id;
        this.page = 1
        this.getimgslist()
      }
    },
    //选择图片
    pickimg(item) {
      let id = item.id;
      if (this.multiple) {
        if (this.selectimg.indexOf(id) == -1) {
          if (this.selectimg.length == this.count) {
            eltips('已到达选择上限', 'error')
            return false
          }
          this.selectimg.push(id);
          this.selectobj.push(item)
        } else {
          let index = this.selectimg.indexOf(id);
          this.selectimg.splice(index, 1);
          this.selectobj.splice(index, 1);
        }
      } else {
        if (this.selectimg.indexOf(id) == -1) {
          this.selectimg = [id]
          this.selectobj = [item]
        } else {
          this.selectimg = []
          this.selectobj = []
        }
      }
    },
    //图片列表模拟hover事件
    hoverimg(id) {
      this.imghover_id = id;
    },
    leaveimg() {
      this.imghover_id = '';
    },
    //返回图片
    confirmimg() {
      let obj = {
        imgids: this.selectimg,
        imgobjs: this.selectobj
      }

      this.$emit('update:managehide', false)
      this.$emit('confirm', obj)
    },
    //选择图片上传水印
    async pickWatermark(e) {
      let that = this;
      let file = e.target.files[0];

      //设置图片key 时间戳+用户id+unitid+文件名
      let userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
      let key = new Date().getTime() + userinfo.id + userinfo.unitid + file.name;
      let token;

      this.watermarkLoading = true
      //获取七牛token
      await this.axios.post('/api/upload/get_qiniu_token').then(res => {
        if (res.code == 200) {
          token = res.data;
        } else {
          this.watermarkLoading = false
          eltips('获取七牛云Token失败，请重试', 'error')
        }
      });

      //设置七牛云参数 config
      let config = {
        concurrentRequestLimit: 2
      };

      //设置七牛云参数 putExtra
      let putExtra = {
        //原文件名
        fname: '',
        //用来放置自定义变量
        params: {},
        //限制上传文件类型
        mimeType: null
      };

      //设置七牛云上传回调事件 observer
      let observer = {
        //上传失败回调
        error(err) {
          that.watermarkLoading = false
          that.$refs['inputbtn'].value = ''
          eltips('上传七牛云失败，请重试', 'error')
        },
        // 上传完成回调
        complete(res) {
          that.watermarkLoading = false
          that.$refs['inputbtn'].value = ''

          let kodo = 'https://qiniuyun.xcooteam.cn/' + res.key

          that.watermarkForm.image = 'https://qiniuyun.xcooteam.cn/' + res.key
          that.watermarkForm.urlsafe = '?watermark/1/image/' + BASE64.urlsafe_encode(kodo) + '/gravity/Center/ws/0.3'
        }
      };

      //创建七牛云上传
      let observable = qiniu.upload(file, key, token, putExtra, config);

      //触发上传并执行上传回调
      observable.subscribe(observer)
    },
  }
};
</script>

<style lang="less">
/* element 样式修改 */
#xsk-manage .el-dialog__footer,
#xsk-manage .el-dialog__body,
#xsk-manage .el-dialog__header {
  padding: 14px 16px !important;
}
#xsk-manage .el-dialog__headerbtn {
  top: 15px;
}
#xsk-manage .el-dialog__header {
  font-weight: bold;
  border-bottom: 1px solid #e9edef;
}
#xsk-manage .el-dialog__body {
  padding: 0 !important;
  border-bottom: 1px solid #e9edef;
}
#xsk-manage
  .el-pagination--small
  .el-pagination__editor.el-input
  .el-input__inner {
  height: 22px !important;
  line-height: 22px !important;
}

.watermarkbox {
  .imgbox {
    border: 1px dashed #e9edef;
    width: 160px;
    height: 80px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 12px;
    position: relative;
    .img {
      width: 100%;
      height: 100%;
    }
    .addicon {
      font-size: 28px;
      color: #1989fa;
    }
    .text {
      font-size: 12px;
      color: #b8b9bd;
      line-height: 16px;
    }
    .inputbtn {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      z-index: 999;
    }
  }

  .textbox {
    margin-top: 10px;
    font-size: 14px;
    color: #939799;
  }
}
</style>
<style scoped lang="less">
#xsk-manage {
  .uploadsbox {
    display: flex;
    flex-wrap: nowrap;
    .leftbox {
      min-width: 200px;
      max-width: 200px;
      border-right: 1px solid #e9edef;
      padding-top: 20px;
      text-align: center;
      .addbtn {
        width: 160px;
      }
      .classbox {
        margin-top: 20px;
        overflow-x: hidden;
        overflow-y: auto;
        height: 440px;
        text-align: left;
        .textbox {
          padding: 0 20px;
          line-height: 40px;
          font-size: 12px;
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          user-select: none;
          .name {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .number {
            min-width: 60px;
            text-align: right;
          }
          &:hover {
            background: #f0faff;
            color: #1989fa;
          }
        }
        .active {
          color: #1989fa;
          background: #f0faff;
          border-right: 2px solid #1989fa;
        }
      }
    }
    .rightbox {
      width: 100%;
      padding: 0 20px;
      .headbox {
        padding: 20px 0 10px;
        display: flex;
        .btn {
          position: relative;
          margin-right: 10px;
          .addbtn {
            width: 90px;
            margin-right: 10px;
          }
          .addinput {
            width: 90px;
            height: 32px;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            cursor: pointer;
          }
        }
      }
      .imglist {
        margin-top: 10px;
        height: 440px;
        .nodata {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;
          .tips {
            margin-top: 10px;
            font-size: 14px;
            position: relative;
            span {
              color: #1989fa;
            }
            .input {
              width: 45px;
              height: 18px;
              position: absolute;
              right: 0;
              top: 0;
              opacity: 0;
            }
          }
        }
        .imgbox {
          height: 384px;
          .imgs {
            display: inline-block;
            vertical-align: top;
            margin-right: 10px;
            margin-bottom: 20px;
            width: 140px;
            height: 182px;
            position: relative;
            cursor: pointer;

            &:nth-child(5n) {
              margin-right: 0px;
            }
            
            .image {
              width: 140px;
              height: 140px;
              box-sizing: border-box;
              border: 1px solid #e9edef;
            }
            .text {
              line-height: 16px;
              font-size: 12px;
              width: 138px;
              word-break: break-all;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }
            .closeicon {
              font-size: 20px;
              position: absolute;
              right: -8px;
              top: -8px;
              color: #999;
            }
            .meng {
              width: 140px;
              height: 140px;
              background: rgba(0, 0, 0, 0.8);
              position: absolute;
              left: 0;
              top: 0;
              z-index: 9;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .sizebox {
              width: 100%;
              height: 20px;
              line-height: 20px;
              position: absolute;
              left: 0;
              bottom: 40px;
              color: #ffffff;
              background: rgba(0, 0, 0, 0.7);
              text-align: center;
            }
          }
        }
        .btnbox {
          height: 36px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
