<!-- 
* @Description 门店页面 
-->
<template>
  <div id="box">
    <div id="one">
      <div class="step">
        <div @click="toRoute('/shopping/setting/shoppingsetting/basics')">
          <span>1</span>
          <span>完善店铺信息</span>
        </div>
        <img src="./imgs/arrow.png" alt="" />
        <div @click="toRoute('/account/editaccount')">
          <span>2</span>
          <span>设置账号体系</span>
        </div>
        <img src="./imgs/arrow.png" alt="" />
        <div @click="toRoute('/channel')">
          <span style="padding: 3px 7px">✔</span>
          <span>设置渠道管理</span>
        </div>
        <img src="./imgs/arrow.png" alt="" />
        <div @click="toRoute('/good/sale?props=1')">
          <span style="padding: 3px 7px">✔</span>
          <span>添加商品</span>
        </div>
        <img src="./imgs/arrow.png" alt="" />
        <div @click="toRoute('/store/style')">
          <span style="padding: 3px 7px">✔</span>
          <span>店铺装修</span>
        </div>
      </div>
    </div>
    <div id="two">
      <div class="two-container-1">
        <div class="welcome">
          <div class="top">
            <div>欢迎登陆系统</div>
            <div>乾坤未定，你我皆是黑马</div>
            <!-- <div>上次登录：{{ userinfo.last_login }} IP: {{ userinfo.ip }}</div> -->
          </div>
          <div class="bottom">
            <div class="bottom-item1">
              <!-- <img :src="userinfo.admin_log" alt="" v-if="userinfo.admin_log" /> -->
              <img src="./imgs/shopdefault.png" alt="" />
              <!-- <img src="./imgs/test1.png" alt="" /> -->
              <div>{{ userinfo.user_name }}</div>
            </div>
            <div class="bottom-item2">
              <div>{{ userinfo.shop_name }}</div>
              <div>到期时间:{{ userinfo.shop_term.expire_time }}</div>
              <div>
                <img src="./imgs/welcome-1.png" alt="" />
                <!-- <img src="./imgs/welcome-2.png" alt="" /> -->
              </div>
            </div>
          </div>
        </div>
        <div class="real-time-info">
          <div class="title">
            <span>实时概况</span>
            <span>更新于 {{ nowDate }}</span>
          </div>
          <div class="details">
            <div class="details-item">
              <div>
                <div>
                  今日支付订单数
                  <el-tooltip
                    class="item"
                    effect="light"
                    content="成功付款的订单数量（不剔除退款订单）"
                    placement="right"
                  >
                    <img src="./imgs/question.png" alt="" />
                  </el-tooltip>
                </div>
                <div>昨日</div>
              </div>
              <div>
                <div>
                  <CountTo
                    :start-val="0"
                    :end-val="topData.today_pay_order"
                  ></CountTo>
                </div>
                <div>{{ topData.yesterday_pay_order }}</div>
              </div>
              <div>
                较昨日&nbsp;
                <span
                  :class="
                    getRatio(
                      topData.today_pay_order,
                      topData.yesterday_pay_order
                    ) === 0
                      ? ''
                      : getRatio(
                          topData.today_pay_order,
                          topData.yesterday_pay_order
                        ) > 0
                      ? 'big'
                      : 'small'
                  "
                >
                  {{
                    getRatio(
                      topData.today_pay_order,
                      topData.yesterday_pay_order
                    ) === 0
                      ? "-"
                      : getRatio(
                          topData.today_pay_order,
                          topData.yesterday_pay_order
                        ) + "%"
                  }}</span
                >
              </div>
            </div>
            <div class="details-item">
              <div>
                <div>
                  今日支付金额
                  <el-tooltip
                    class="item"
                    effect="light"
                    content="成功付款的订单金额之和（不剔除退款）"
                    placement="right"
                  >
                    <img src="./imgs/question.png" alt="" />
                  </el-tooltip>
                </div>
                <div>昨日</div>
              </div>
              <div>
                <div>
                  <CountTo
                    :start-val="0"
                    :end-val="topData.today_pay_money"
                  ></CountTo>
                </div>
                <div>{{ topData.yesterday_pay_money }}</div>
              </div>
              <div>
                较昨日&nbsp;
                <span
                  :class="
                    getRatio(
                      topData.today_pay_money,
                      topData.yesterday_pay_money
                    ) === 0
                      ? ''
                      : getRatio(
                          topData.today_pay_money,
                          topData.yesterday_pay_money
                        ) > 0
                      ? 'big'
                      : 'small'
                  "
                >
                  {{
                    getRatio(
                      topData.today_pay_money,
                      topData.yesterday_pay_money
                    ) === 0
                      ? "-"
                      : getRatio(
                          topData.today_pay_money,
                          topData.yesterday_pay_money
                        ) + "%"
                  }}</span
                >
              </div>
            </div>
            <div class="details-item">
              <div>
                <div>
                  今日新增会员数
                  <el-tooltip
                    class="item"
                    effect="light"
                    content="新注册的会员数量"
                    placement="right"
                  >
                    <img src="./imgs/question.png" alt="" />
                  </el-tooltip>
                </div>
                <div>昨日</div>
              </div>
              <div>
                <div>
                  <CountTo
                    :start-val="0"
                    :end-val="topData.today_user_count"
                  ></CountTo>
                </div>
                <div>{{ topData.yesterday_user_count }}</div>
              </div>
              <div>
                较昨日&nbsp;
                <span
                  :class="
                    getRatio(
                      topData.today_user_count,
                      topData.yesterday_user_count
                    ) === 0
                      ? ''
                      : getRatio(
                          topData.today_user_count,
                          topData.yesterday_user_count
                        ) > 0
                      ? 'big'
                      : 'small'
                  "
                >
                  {{
                    getRatio(
                      topData.today_user_count,
                      topData.yesterday_user_count
                    ) === 0
                      ? "-"
                      : getRatio(
                          topData.today_user_count,
                          topData.yesterday_user_count
                        ) + "%"
                  }}</span
                >
              </div>
            </div>
            <div class="details-item">
              <div>
                <div>
                  昨日支付人数(人)
                  <el-tooltip
                    class="item"
                    effect="light"
                    content="成功付款购买商品（不剔除退款）的会员数，一人多次付款记为一人"
                    placement="right"
                  >
                    <img src="./imgs/question.png" alt="" />
                  </el-tooltip>
                </div>
                <div>前日</div>
              </div>
              <div>
                <div>
                  <CountTo
                    :start-val="0"
                    :end-val="topData.yesterday_pay_count"
                  ></CountTo>
                </div>
                <div>{{ topData.before_pay_count }}</div>
              </div>
              <div>
                较前日&nbsp;
                <span
                  :class="
                    getRatio(
                      topData.yesterday_pay_count,
                      topData.before_pay_count
                    ) === 0
                      ? ''
                      : getRatio(
                          topData.yesterday_pay_count,
                          topData.before_pay_count
                        ) > 0
                      ? 'big'
                      : 'small'
                  "
                >
                  {{
                    getRatio(
                      topData.yesterday_pay_count,
                      topData.before_pay_count
                    ) === 0
                      ? "-"
                      : getRatio(
                          topData.yesterday_pay_count,
                          topData.before_pay_count
                        ) + "%"
                  }}</span
                >
              </div>
            </div>
            <div class="details-item">
              <div>
                <div>
                  昨日浏览量(次)
                  <el-tooltip
                    class="item"
                    effect="light"
                    content="页面被访问的次数，一个人访问多次记为多次"
                    placement="right"
                  >
                    <img src="./imgs/question.png" alt="" />
                  </el-tooltip>
                </div>
                <div>前日</div>
              </div>
              <div>
                <div>
                  <CountTo
                    :start-val="0"
                    :end-val="topData.yesterday_pv"
                  ></CountTo>
                </div>
                <div>{{ topData.before_pv }}</div>
              </div>
              <div>
                较前日&nbsp;
                <span
                  :class="
                    getRatio(topData.yesterday_pv, topData.before_pv) === 0
                      ? ''
                      : getRatio(topData.yesterday_pv, topData.before_pv) > 0
                      ? 'big'
                      : 'small'
                  "
                >
                  {{
                    getRatio(topData.yesterday_pv, topData.before_pv) === 0
                      ? "-"
                      : getRatio(topData.yesterday_pv, topData.before_pv) + "%"
                  }}</span
                >
              </div>
            </div>
            <div class="details-item">
              <div>
                <div>
                  昨日客单价(元)
                  <el-tooltip
                    class="item"
                    effect="light"
                    content="实际付款购买商品的会员数（去除退款），客单价=付款金额/付款人数"
                    placement="right"
                  >
                    <img src="./imgs/question.png" alt="" />
                  </el-tooltip>
                </div>
                <div>前日</div>
              </div>
              <div>
                <div>
                  <CountTo
                    :start-val="0"
                    :end-val="topData.yesterday_order_avg_price"
                  ></CountTo>
                </div>
                <div>{{ topData.before_order_avg_price }}</div>
              </div>
              <div>
                较前日&nbsp;
                <span
                  :class="
                    getRatio(
                      topData.yesterday_order_avg_price,
                      topData.before_order_avg_price
                    ) === 0
                      ? ''
                      : getRatio(
                          topData.yesterday_order_avg_price,
                          topData.before_order_avg_price
                        ) > 0
                      ? 'big'
                      : 'small'
                  "
                >
                  {{
                    getRatio(
                      topData.yesterday_order_avg_price,
                      topData.before_order_avg_price
                    ) === 0
                      ? "-"
                      : getRatio(
                          topData.yesterday_order_avg_price,
                          topData.before_order_avg_price
                        ) + "%"
                  }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="two-container-2">
        <div class="backlog">
          <div class="title">
            <span>待办事项</span>
            <!-- <span>布局</span> -->
          </div>
          <div class="details">
            <div class="details-item" @click="toRoute('/order/wait?props=1')">
              <div>
                <CountTo
                  :start-val="0"
                  :end-val="waitedData.not_send"
                ></CountTo>
              </div>
              <div>
                等待发货
                <el-tooltip
                  class="item"
                  effect="light"
                  content="等待发货的订单数量"
                  placement="right"
                >
                  <img src="./imgs/question.png" alt="" />
                </el-tooltip>
              </div>
            </div>
            <div
              class="details-item"
              @click="toRoute('/order/waitpay?props=0')"
            >
              <div>
                <CountTo :start-val="0" :end-val="waitedData.not_pay"></CountTo>
              </div>
              <div>
                等待付款
                <el-tooltip
                  class="item"
                  effect="light"
                  content="等待付款的订单数量"
                  placement="right"
                >
                  <img src="./imgs/question.png" alt="" />
                </el-tooltip>
              </div>
            </div>
            <div
              class="details-item"
              @click="toRoute('/order/service?props=4')"
            >
              <div>
                <CountTo
                  :start-val="0"
                  :end-val="waitedData.after_sale"
                ></CountTo>
              </div>
              <div>
                售后中订单
                <el-tooltip
                  class="item"
                  effect="light"
                  content="正在进行退换货中的订单数量"
                  placement="right"
                >
                  <img src="./imgs/question.png" alt="" />
                </el-tooltip>
              </div>
            </div>
            <div class="details-item">
              <div>
                <CountTo
                  :start-val="0"
                  :end-val="waitedData.few_stock"
                ></CountTo>
              </div>
              <div>
                库存紧张
                <el-tooltip
                  class="item"
                  effect="light"
                  content="即将售罄的商品数量"
                  placement="right"
                >
                  <img src="./imgs/question.png" alt="" />
                </el-tooltip>
              </div>
            </div>
            <div class="details-item" @click="toRoute('/good/sellout?props=2')">
              <div>
                <CountTo
                  :start-val="0"
                  :end-val="waitedData.empty_stock"
                ></CountTo>
              </div>
              <div>
                商品售罄
                <el-tooltip
                  class="item"
                  effect="light"
                  content="已售罄的商品数量"
                  placement="right"
                >
                  <img src="./imgs/question.png" alt="" />
                </el-tooltip>
              </div>
            </div>
            <div
              class="details-item"
              @click="toRoute('/shopping/distribution/manage/pending')"
            >
              <div>
                <CountTo
                  :start-val="0"
                  :end-val="waitedData.examine_distribution"
                ></CountTo>
              </div>
              <div>
                待审核分销商
                <el-tooltip
                  class="item"
                  effect="light"
                  content="分销的待审核分销商"
                  placement="right"
                >
                  <img src="./imgs/question.png" alt="" />
                </el-tooltip>
              </div>
            </div>
            <div
              class="details-item"
              @click="
                toRoute('/shopping/distribution/withdrawal/payment?props=2')
              "
            >
              <div>
                <CountTo
                  :start-val="0"
                  :end-val="waitedData.pay_withdrawal"
                ></CountTo>
              </div>
              <div>
                待打款提现
                <el-tooltip
                  class="item"
                  effect="light"
                  content="分销的待打款提现"
                  placement="right"
                >
                  <img src="./imgs/question.png" alt="" />
                </el-tooltip>
              </div>
            </div>
            <div
              class="details-item"
              @click="toRoute('/shopping/distribution/withdrawal/stay?props=1')"
            >
              <div>
                <CountTo
                  :start-val="0"
                  :end-val="waitedData.examine_withdrawal"
                ></CountTo>
              </div>
              <div>
                待审核提现
                <el-tooltip
                  class="item"
                  effect="light"
                  content="多商户的待审核提现"
                  placement="right"
                >
                  <img src="./imgs/question.png" alt="" />
                </el-tooltip>
              </div>
            </div>
            <div class="details-item" @click="toRoute('/good/examine')">
              <div>
                <CountTo
                  :start-val="0"
                  :end-val="waitedData.examine_goods"
                ></CountTo>
              </div>
              <div>
                待审核商品
                <el-tooltip
                  class="item"
                  effect="light"
                  content="多商户的待审核商品"
                  placement="right"
                >
                  <img src="./imgs/question.png" alt="" />
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="three">
      <div class="commonly">
        <div class="title">
          <span>常用功能</span>
        </div>
        <div class="details">
          <div class="details-item" @click="toRoute('/store/style')">
            <div>
              <img src="./imgs/icon-1.png" alt="" />
            </div>
            <div>店铺装修</div>
          </div>
          <div class="details-item" @click="toRoute('/channel')">
            <div>
              <img src="./imgs/icon-2.png" alt="" />
            </div>
            <div>发布微信小程序</div>
          </div>
          <div class="details-item" @click="toRoute('/good/sale?props=1')">
            <div>
              <img src="./imgs/icon-3.png" alt="" />
            </div>
            <div>发布商品</div>
          </div>
          <div class="details-item" @click="toRoute('/order/wait?props=1')">
            <div>
              <img src="./imgs/icon-4.png" alt="" />
            </div>
            <div>去发货</div>
          </div>
          <div class="details-item" @click="toRoute('/order/service?props=4')">
            <div>
              <img src="./imgs/icon-5.png" alt="" />
            </div>
            <div>售后订单</div>
          </div>
          <div class="details-item" @click="toRoute('/shopping/vip/list')">
            <div>
              <img src="./imgs/icon-6.png" alt="" />
            </div>
            <div>会员管理</div>
          </div>
          <div class="details-item" @click="toRoute('/finance/recharge')">
            <div>
              <img src="./imgs/icon-7.png" alt="" />
            </div>
            <div>营收对账单</div>
          </div>
          <div
            class="details-item"
            @click="toRoute('/shopping/marketing/discount')"
          >
            <div>
              <img src="./imgs/icon-8.png" alt="" />
            </div>
            <div>创建活动</div>
          </div>
          <div class="details-item" @click="toRoute('/application')">
            <div>
              <img src="./imgs/icon-9.png" alt="" />
            </div>
            <div>我的应用</div>
          </div>
        </div>
      </div>
      <div class="trading">
        <div class="title">
          <div>交易概况</div>
          <div class="buttons">
            <el-select
              v-model="trading"
              placeholder="请选择"
              @change="setcharts1(true)"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-button
              :type="dateSelection.trading === 0 ? 'primary' : 'default'"
              size="mini"
              @click="setcharts1(false, 0)"
              >7天</el-button
            >
            <el-button
              :type="dateSelection.trading === 1 ? 'primary' : 'default'"
              size="mini"
              @click="setcharts1(false, 1)"
              >15天</el-button
            >
            <el-button
              :type="dateSelection.trading === 2 ? 'primary' : 'default'"
              size="mini"
              @click="setcharts1(false, 2)"
              >30天</el-button
            >
          </div>
        </div>
        <div
          class="echarts"
          id="echarts1"
          style="height: 270px; width: auto"
        ></div>
      </div>
      <div class="commodity-rank">
        <div class="title">
          <div>商品排行榜</div>
          <div class="buttons">
            <el-button
              :type="dateSelection.commodity === 0 ? 'primary' : 'default'"
              size="mini"
              @click="goodsRanking(1)"
              >7天</el-button
            >
            <el-button
              :type="dateSelection.commodity === 1 ? 'primary' : 'default'"
              size="mini"
              @click="goodsRanking(2)"
              >15天</el-button
            >
            <el-button
              :type="dateSelection.commodity === 2 ? 'primary' : 'default'"
              size="mini"
              @click="goodsRanking(3)"
              >30天</el-button
            >
          </div>
        </div>
        <div class="details no-data" v-if="goodsRankingData.length === 0">
          <img src="./imgs/empty.png" alt="" />
          <div>暂无数据 ~</div>
        </div>
        <div class="details" v-else>
          <div class="details-item-good table-header">
            <div>TOP</div>
            <div style="position: relative; right: 5px">商品</div>
            <div>销量</div>
            <div>销售额</div>
          </div>
          <div
            class="details-item-good"
            v-for="(x, i) in goodsRankingData"
            :key="x.id"
          >
            <div v-if="i === 0"><img src="./imgs/one.png" alt="" /></div>
            <div v-else-if="i === 1"><img src="./imgs/two.png" alt="" /></div>
            <div v-else-if="i === 2"><img src="./imgs/three.png" alt="" /></div>
            <div v-else>{{ i + 1 }}</div>
            <div>{{ x.name }}</div>
            <div>{{ x.sale }}</div>
            <div>{{ x.order_money || x.sale_money }}</div>
          </div>
        </div>
      </div>
      <div class="member-rank">
        <div class="title">
          <div>会员排行</div>
          <div class="buttons">
            <el-button
              :type="dateSelection.member === 0 ? 'primary' : 'default'"
              size="mini"
              @click="userRanking(1)"
              >7天</el-button
            >
            <el-button
              :type="dateSelection.member === 1 ? 'primary' : 'default'"
              size="mini"
              @click="userRanking(2)"
              >15天</el-button
            >
            <el-button
              :type="dateSelection.member === 2 ? 'primary' : 'default'"
              size="mini"
              @click="userRanking(3)"
              >30天</el-button
            >
          </div>
        </div>
        <div class="details no-data" v-if="userRankingData.length === 0">
          <img src="./imgs/empty.png" alt="" />
          <div>暂无数据 ~</div>
        </div>
        <div class="details" v-else>
          <!-- <img src="./imgs/empty.png" alt="" /> -->
          <div class="details-item table-header">
            <div>TOP</div>
            <div>手机号</div>
            <div>成交额</div>
          </div>
          <div
            class="details-item"
            v-for="(x, i) in userRankingData"
            :key="x.id"
          >
            <div v-if="i === 0"><img src="./imgs/one.png" alt="" /></div>
            <div v-else-if="i === 1"><img src="./imgs/two.png" alt="" /></div>
            <div v-else-if="i === 2"><img src="./imgs/three.png" alt="" /></div>
            <div v-else>{{ i + 1 }}</div>
            <div>{{ x.phone }}</div>
            <div>{{ x.order_money }}</div>
          </div>
        </div>
      </div>
      <div class="shop-rank">
        <div class="title">
          <div>{{ shopRank }}</div>
          <div class="buttons">
            <el-button
              :type="dateSelection.shop === 0 ? 'primary' : 'default'"
              size="mini"
              @click="dateSelection.shop = 0"
              >7天</el-button
            >
            <el-button
              :type="dateSelection.shop === 1 ? 'primary' : 'default'"
              size="mini"
              @click="dateSelection.shop = 1"
              >15天</el-button
            >
            <el-button
              :type="dateSelection.shop === 2 ? 'primary' : 'default'"
              size="mini"
              @click="dateSelection.shop = 2"
              >30天</el-button
            >
          </div>
        </div>
        <div class="details no-data">
          <img src="./imgs/empty.png" alt="" />
          <div>暂无数据 ~</div>
        </div>
      </div>
    </div>
    <div id="four">
      <div class="service">
        <div class="details no-data">
          <img src="./imgs/empty.png" alt="" />
          <div>暂无客服 ~</div>
        </div>
      </div>
      <div class="marketing">
        <div class="title">
          <span>营销渠道收入比例</span>
          <div id="echarts2" style="height: 194px; width: auto"></div>
        </div>
      </div>
      <div class="member-registration">
        <div class="title">
          <span>会员注册渠道比例</span>
          <div id="echarts3" style="height: 194px; width: auto"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from "echarts";
import { eltips } from "@/util/util.js";
import CountTo from "./components/CountTo.vue";

export default {
  data() {
    return {
      charts: null,
      value: "",
      options: [
        {
          value: 1,
          label: "成交总额(元)",
          filed: "deal_money",
        },
        {
          value: 2,
          label: "订单总数(笔)",
          filed: "order_count",
        },
        {
          value: 3,
          label: "笔单价(元)",
          filed: "order_unit_price",
        },
        {
          value: 4,
          label: "支付人数(人)",
          filed: "pay_user_count",
        },
        {
          value: 5,
          label: "客单价(元)",
          filed: "user_unit_price",
        },
        {
          value: 6,
          label: "浏览次数(PV)",
          filed: "visit_pv",
        },
        {
          value: 7,
          label: "独立访客(UV)",
          filed: "visit_uv",
        },
        {
          value: 8,
          label: "新增用户(人)",
          filed: "user_count",
        },
      ],
      userinfo: {},
      topData: {
        today_pay_order: 0,
        yesterday_pay_order: 0,
        today_pay_money: 0,
        yesterday_pay_money: 0,
        today_user_count: 0,
        yesterday_user_count: 0,
        yesterday_pay_count: 0,
        before_pay_count: 0,
        yesterday_pv: 0,
        before_pv: 0,
        yesterday_order_avg_price: 0,
        before_order_avg_price: 0,
        user_count: 0,
      },
      // 日期选择
      dateSelection: {
        trading: 0, // 交易概况
        commodity: 0, // 商品排行榜
        member: 0, // 会员排行
        shop: 0, // 门店排行
      },
      trading: 1, // 交易概况选择
      charts1Data: [], // 交易概况数据
      userRankingData: [], // 会员排行数据
      // userRankingDataTotal: 0,
      waitedData: {
        not_send: 0,
        not_pay: 0,
        after_sale: 0,
        few_stock: 0,
        empty_stock: 0,
        examine_distribution: 0,
        pay_withdrawal: 0,
        examine_withdrawal: 0,
        examine_goods: 0,
      }, // 待办事项数据
      goodsRankingData: [], // 商品排行榜数据
    };
  },
  props: {
    api: {
      type: String,
      default: "",
    },
  },
  components: {
    CountTo,
  },
  computed: {
    shopRank() {
      if (this.api === "retail/admin") {
        return "门店排行";
      } else {
        return "商家排行";
      }
    },
    nowDate() {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let seconds = date.getSeconds();
      hours = hours < 10 ? `0${hours}` : hours;
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      seconds = seconds < 10 ? `0${seconds}` : seconds;
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
  },
  created() {
    // 获取个人信息
    this.userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
    // 会员排行
    this.userRanking();
    // 商品排行榜
    this.goodsRanking();
    // 待办事项
    this.topWaited();
  },
  async mounted() {
    // 头部统计
    await this.getTopCount();
    // 交易概况
    this.setcharts1();
    this.setcharts2();
    this.setcharts3();
  },
  methods: {
    goodsRanking(type = 1) {
      this.dateSelection.commodity = type - 1;
      this.axios
        .get(`api/${this.api}/data/goods_ranking`, {
          params: {
            limit: 7,
            page: 1,
            type,
          },
        })
        .then((res) => {
          if (res.code == 200) {
            this.goodsRankingData = res.data.data;
          } else {
            eltips(res.msg, "error");
          }
        });
    },
    topWaited() {
      let port = "";
      if (this.api === "ecshop" || this.api === "retail/admin") {
        port = `api/${this.api}/data/top_waited`;
      } else {
        port = `api/${this.api}/top_waited`;
      }
      this.axios
        .get(port, {
          params: {},
        })
        .then((res) => {
          if (res.code == 200) {
            this.waitedData = res.data;
          } else {
            eltips(res.msg, "error");
          }
        });
    },
    // 计算较昨日比例
    getRatio(num1, num2) {
      if (num1 === 0 && num2 === 0) return 0;
      // 只保留4位小数
      return Number((((num1 - num2) / num2) * 100).toFixed(2)) === Infinity
        ? 100
        : Number((((num1 - num2) / num2) * 100).toFixed(2));
    },
    userRanking(type = 1) {
      this.dateSelection.member = type - 1;
      this.axios
        .get(`api/${this.api}/data/user_ranking`, {
          params: {
            limit: 7,
            page: 1,
            type,
            // keyword: "",
            // member_level_id: "",
          },
        })
        .then((res) => {
          if (res.code == 200) {
            // this.userRankingDataTotal = res.data.total;
            this.userRankingData = res.data.data;
            // 手机号中间变成*
            this.userRankingData.forEach((item) => {
              item.phone = item.phone.replace(
                /(\d{3})\d{4}(\d{4})/,
                "$1****$2"
              );
            });
          } else {
            eltips(res.msg, "error");
          }
        });
    },
    //头部统计
    async getTopCount() {
      let port = "";
      if (this.api === "retail/admin") {
        port = `api/${this.api}/data/top_statistics`;
      } else {
        port = `/api/${this.api}/top_statistics`;
      }
      await this.axios.get(port).then((res) => {
        if (res.code == 200) {
          this.topData = res.data;
        } else {
          eltips(res.msg, "error");
        }
      });
    },
    toRoute(path) {
      this.$router.push(path);
    },
    async setcharts1(getapi = true, date = 0) {
      // if (this.picktime && this.picktime.length == 2) {
      //   data.start_time = this.picktime[0];
      //   data.end_time = this.picktime[1];
      // }
      const start_time = (() => {
        let date = new Date();
        date.setDate(date.getDate() - 30);
        let year = date.getFullYear().toString();
        let month = ("0" + (date.getMonth() + 1)).slice(-2);
        let day = ("0" + date.getDate()).slice(-2);
        let pastDate = `${year}-${month}-${day}`;
        return pastDate;
      })();
      const end_time = (() => {
        let date = new Date();
        let year = date.getFullYear().toString();
        let month = ("0" + (date.getMonth() + 1)).slice(-2);
        let day = ("0" + date.getDate()).slice(-2);
        let today = `${year}-${month}-${day}`;
        return today;
      })();
      let port = "";
      if (this.api === "retail/admin") {
        port = `/api/${this.api}/data/data_chart`;
      } else {
        port = `/api/${this.api}/data_chart`;
      }
      if (getapi) {
        await this.axios
          .get(port, {
            params: {
              // // 获取过去30天日期，格式yy-mm-dd
              // start_time,
              // // 获取当前日期，格式yy-mm-dd
              // end_time,
              type: "day",
              filed: this.options[this.trading - 1].filed,
            },
          })
          .then((res) => {
            if (res.code == 200) {
              this.charts1Data = res.data;
            } else {
              eltips(res.msg, "error");
            }
          });
      }
      let charts1Data = [];
      // 前端显示
      this.dateSelection.trading = date;
      // 用前端显示的数据切换echarts
      if (this.dateSelection.trading === 0) {
        charts1Data = this.charts1Data.slice(-7);
      } else if (this.dateSelection.trading === 1) {
        charts1Data = this.charts1Data.slice(-15);
      } else {
        charts1Data = this.charts1Data.slice(-30);
      }
      const xData = charts1Data.map((item) => item.time);
      const seriesData = charts1Data.map((item) => item.count);
      // seriesData数组遍历相加，保留2位小数
      const seriesDataTotal = Number(
        seriesData
          .reduce((total, num) => {
            return total + num;
          }, 0)
          .toFixed(2)
      );
      this.charts = echarts.init(document.getElementById("echarts1"));
      // echarts title
      const day =
        this.dateSelection.trading === 0
          ? `7天`
          : this.dateSelection.trading === 1
          ? `15天`
          : `30天`;
      const label = this.options[this.trading - 1].label.replace(
        /\([^)]+\)/,
        ""
      );
      let unit = "";
      if (this.trading === 6) {
        unit = "次";
      } else if (this.trading === 7) {
        unit = "人";
      } else {
        unit = this.options[this.trading - 1].label.match(/\(([^)]+)\)/)[1];
      }
      this.charts.setOption({
        grid: {
          left: "5%",
          right: "8%",
          bottom: "0%",
          containLabel: true,
        },
        title: [
          {
            text: `${day}${label}总计：{style|${seriesDataTotal}}  ${unit}`,
            left: "5%",
            top: "1%",
            textStyle: {
              fontSize: 12,
              color: "#9d9d9d",
              // fontWeight: "bold",
              rich: {
                style: {
                  fontWeight: "bold",
                  fontSize: 20,
                  color: "#000",
                },
              },
            },
          },
        ],
        xAxis: {
          type: "category",
          data: xData,
          axisLine: {
            show: false,
          },
          boundaryGap: false,
          // axisLabel: {
          //   interval: 1, // 设置标签显示间隔，0 表示显示所有标签
          // },
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: false,
          },
        },
        series: [
          {
            data: seriesData,
            type: "line",
            symbol: "circle",
            showSymbol: false,
            hoverAnimation: true,
            lineStyle: {
              width: 3,
              color: "#0D4CFD",
            },
            smooth: true,
            areaStyle: {
              color: "#0D4CFD",
              opacity: 0.2,
            },
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "line",
            lineStyle: {
              type: "dashed",
              color: "#F43054",
            },
          },
          formatter: function (params) {
            // return params.name
            return (
              params[0].name +
              "<br/>支付金额：<span style='color:#F43054'>" +
              params[0].value
            );
          },
        },
      });
    },
    setcharts2() {
      this.charts = echarts.init(document.getElementById("echarts2"));
      this.charts.setOption({
        color: [
          "#32B62F",
          "#3E66F4",
          "#F3933B",
          "#7F5FFE",
          "#EFBDA1",
          "#22C4DA",
          "#F173A0",
          "#EFC019",
        ],
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        toolbox: {
          show: true,
        },
        legend: {
          itemlength: 20,
          itemWidth: 15,
          // orient: "vertical",
          icon: "circle",
          left: "70%",
          align: "left",
          top: "middle",
          textStyle: {
            color: "#909399",
          },
          height: 150,
          left: "55%",
        },
        series: [
          {
            label: {
              show: false,
            },
            name: "营销渠道收入比例",
            type: "pie",
            radius: ["50%", "70%"],
            data: [
              { value: 0, name: "公众号" },
              { value: this.topData.user_count, name: "微信小程序" },
              { value: 0, name: "H5" },
              { value: 0, name: "百度小程序" },
              { value: 0, name: "支付宝小程序" },
              { value: 0, name: "APP" },
              { value: 0, name: "抖音小程序" },
            ],
            // 左移
            center: ["30%", "50%"],
          },
        ],
      });
    },
    setcharts3() {
      this.charts = echarts.init(document.getElementById("echarts3"));
      this.charts.setOption({
        color: [
          "#32B62F",
          "#3E66F4",
          "#F3933B",
          "#7F5FFE",
          "#EFBDA1",
          "#22C4DA",
          "#F173A0",
          "#EFC019",
        ],
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        toolbox: {
          show: true,
        },
        legend: {
          itemlength: 20,
          itemWidth: 15,
          // orient: "vertical",
          icon: "circle",
          left: "70%",
          align: "left",
          top: "middle",
          textStyle: {
            color: "#909399",
          },
          height: 150,
          left: "55%",
        },
        series: [
          {
            label: {
              show: false,
            },
            name: "会员注册渠道比例",
            type: "pie",
            radius: ["50%", "70%"],
            data: [
              { value: 0, name: "公众号" },
              { value: this.topData.user_count, name: "微信小程序" },
              { value: 0, name: "H5" },
              { value: 0, name: "百度小程序" },
              { value: 0, name: "支付宝小程序" },
              { value: 0, name: "APP" },
              { value: 0, name: "抖音小程序" },
            ],
            // 左移
            center: ["30%", "50%"],
          },
        ],
      });
    },
  },
};
</script>

<style lang="less" scoped>
#box {
  overflow-x: hidden;
  overflow-y: auto;
}
// 步骤条
#one {
  > .step {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    margin: 10px;
    padding: 15px;
    border-radius: 10px;
    font-size: 17px;
    margin-right: 18px;
    > div {
      cursor: pointer;
      > span:nth-child(1) {
        border-radius: 50%;
        padding: 3px 9px;
        border: 1px solid #89b1f8;
        margin-right: 5px;
        color: #479cf7;
      }
    }
    > .symbol {
      color: #89b1f8;
    }
  }
}

#two {
  width: 100%;
  display: flex;
  margin: 10px;
  margin-top: 20px;
  > .two-container-1 {
    padding: 20px;
    background: #fff;
    display: flex;
    width: 75%;
    border-radius: 10px;
    // 欢迎
    .welcome {
      width: 400px;
      // width: 23%;
      > .top {
        background: url("./imgs/day-bg2.png") no-repeat;
        background-size: 100% 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 20px 10px;
        color: #fff;
        > div:nth-child(1) {
          font-size: 19px;
          font-weight: bold;
        }
        > div:nth-child(2) {
          font-size: 12px;
          color: hsla(0, 0%, 98%, 0.9);
        }
        > div:nth-child(3) {
          font-size: 12px;
          color: hsla(0, 0%, 100%, 0.63);
          margin-top: 20px;
          white-space: nowrap;
        }
      }
      > .bottom {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background: linear-gradient(180deg, #d6e6ff, #fdfdff);
        display: flex;
        align-items: center;
        padding: 20px 10px;
        padding-bottom: 28px;
        position: relative;
        bottom: 7px;
        > .bottom-item1 {
          margin-right: 20px;
          text-align: center;
          position: relative;
          > img {
            width: 70px;
          }
          > img:nth-child(2) {
            width: 70px;
            position: absolute;
            left: 0;
          }
          > div {
            color: #eed8a0;
            background: #2d2e31;
            font-size: 12px;
            padding: 2px;
            border-radius: 4px;
            position: relative;
            bottom: 20px;
          }
        }
        > .bottom-item2 {
          > div:nth-child(1) {
            font-weight: bold;
            font-size: 17px;
          }
          > div:nth-child(2) {
            color: rgba(0, 0, 0, 0.4);
            font-size: 12px;
          }
          > div:nth-child(3) {
            margin-top: 2px;
            > img {
              width: 17px;
              cursor: pointer;
            }
            > img:nth-child(2) {
              margin-left: 10px;
            }
          }
        }
      }
    }
    // 实时概况
    .real-time-info {
      // width: 72.5%;
      margin-left: 10px;
      > .title {
        margin-left: 15px;
        > span:nth-child(1) {
          font-size: 16px;
          font-weight: bold;
          margin-right: 5px;
        }
        > span:nth-child(2) {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.4);
        }
      }
      > .details {
        display: flex;
        flex-wrap: wrap;
        > .details-item {
          width: 31.5%;
          background: #f9f9f9;
          padding: 10px 20px;
          margin: 10px 9px;
          text-align: center;
          border-radius: 10px;
          > div {
            display: flex;
            justify-content: space-between;
            > div:nth-child(1) {
              display: flex;
              align-items: center;
              img {
                margin-left: 10px;
                width: 13px;
                cursor: pointer;
              }
            }
          }
          > div:nth-child(2) {
            > div:nth-child(1) {
              font-size: 28px;
              color: #000;
              font-weight: bold;
            }
          }
          > div:nth-child(3) {
            font-size: 12px;
            justify-content: flex-start;
            .big {
              color: red;
            }
            .small {
              color: #4abd70;
            }
            // 向上的小三角形红色
            .big::after {
              content: "";
              display: inline-block;
              width: 0;
              height: 0;
              border-left: 4px solid transparent;
              border-right: 4px solid transparent;
              border-bottom: 4px solid red;
              margin-left: 5px;
              margin-bottom: 2px;
            }
            // 向下的小三角形绿色
            .small::after {
              content: "";
              display: inline-block;
              width: 0;
              height: 0;
              border-left: 4px solid transparent;
              border-right: 4px solid transparent;
              border-top: 4px solid #4abd70;
              margin-left: 5px;
              margin-bottom: 2px;
            }
          }
        }
      }
    }
  }
  > .two-container-2 {
    padding: 10px;
    background: #fff;
    display: flex;
    width: 25%;
    margin: 0 20px;
    margin-right: 28px;
    border-radius: 10px;
    // 代办事项
    .backlog {
      width: 100%;
      > .title {
        margin-left: 13px;
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        margin-bottom: 7px;
        > span:nth-child(1) {
          font-size: 16px;
          font-weight: bold;
          margin-right: 5px;
        }
        > span:nth-child(2) {
          font-size: 13px;
          color: #52a2f7;
        }
      }
      > .details {
        display: flex;
        flex-wrap: wrap;
        > .details-item {
          background: #f9f9f9;
          width: 31%;
          padding: 6px 7px;
          text-align: center;
          margin: 5px;
          border-radius: 10px;
          cursor: pointer;
          > div:nth-child(1) {
            font-size: 24px;
            color: #000;
            font-weight: bold;
          }
          > div:nth-child(2) {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              margin-left: 3px;
              width: 13px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

#three {
  width: 100%;
  display: flex;
  margin: 10px;
  margin-top: 20px;
  // 常用功能
  > .commonly {
    width: 18%;
    background: #fff;
    border-radius: 10px;
    // margin-top: 20px;
    > .title {
      margin: 20px;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .details {
      display: flex;
      flex-wrap: wrap;
      font-weight: bold;
      font-size: 12px;
      margin-bottom: 10px;
      .details-item {
        cursor: pointer;
        img {
          font-size: 30px;
          // background: #479cf7;
          padding: 5px;
          height: 55px;
          width: auto;
          border-radius: 10px;
        }
        width: 33%;
        // padding: 20px;
        margin: 10px 0;
        text-align: center;
      }
    }
  }
  // 交易概况
  > .trading {
    background: #fff;
    margin-left: 20px;
    width: 29%;
    border-radius: 10px;
    > .title {
      margin: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div:nth-child(1) {
        font-size: 16px;
        font-weight: bold;
      }
    }
    .buttons {
      display: flex;
      .el-select {
        width: 130px;
      }
    }
  }
  // 商品排行榜、会员排行、门店排行
  > .commodity-rank,
  > .member-rank,
  > .shop-rank {
    // width: 15%;
    border-radius: 10px;
    width: 15.8%;
    background: #fff;
    margin-left: 20px;
    position: relative;
    height: 360px;
    overflow: auto;
    > .title {
      margin: 20px;
      white-space: nowrap;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div:nth-child(1) {
        font-size: 15px;
        font-weight: bold;
      }
    }
    > .details {
      // position: absolute;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
      font-size: 12px;
      > .details-item {
        margin-top: 10px;
        display: flex;
        line-height: 27px;
        > div:nth-child(1) {
          width: 10%;
          margin-left: 20px;
          text-align: center;
          > img {
            height: 18px;
            position: relative;
            top: 2px;
          }
        }
        > div:nth-child(2) {
          width: 60%;
          text-align: center;
          // margin-right: 30px;
          margin-left: 10px;
        }
        > div:nth-child(3) {
          width: 25%;
          margin-right: 20px;
          text-align: right;
          color: red;
        }
      }
      > .details-item-good {
        margin-top: 10px;
        display: flex;
        line-height: 26px;
        > div:nth-child(1) {
          width: 10%;
          margin-left: 20px;
          text-align: center;
          > img {
            height: 20px;
            position: relative;
            top: 2px;
          }
        }
        > div:nth-child(2) {
          width: 40%;
          text-align: left;
          margin-left: 30px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        > div:nth-child(3) {
          width: 30%;
          text-align: center;
          margin-left: 10px;
        }
        > div:nth-child(4) {
          width: 25%;
          margin-right: 20px;
          text-align: right;
          color: red;
        }
      }
      > .table-header {
        font-size: 13px;
        margin-top: -5px;
        > div {
          color: #7c817c !important;
        }
      }
    }
    > .no-data {
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      > img {
        width: 85px;
      }
    }
  }
  > .shop-rank {
    margin-right: 20px;
  }
}

#four {
  margin: 20px 10px;
  width: 100%;
  display: flex;
  // 常用功能
  .service {
    background: #fff;
    width: 18%;
    position: relative;
    height: 258px;
    border-radius: 10px;
    > .details {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 12px;
    }
    > .no-data {
      > img {
        width: 85px;
      }
      text-align: center;
    }
  }
  // 营销渠道收入比例、会员注册渠道比例
  .marketing,
  .member-registration {
    width: 39.2%;
    margin-left: 20px;
    background: #fff;
    border-radius: 10px;
    .title {
      margin: 20px;
      font-size: 16px;
      font-weight: bold;
    }
  }
}

// 公共
.el-select {
  width: 120px;
}
.el-button {
  margin: 0;
}
// 蓝色的线
.title {
  > *:nth-child(1)::before {
    content: "";
    display: inline-block;
    width: 3px;
    height: 13px;
    background: #4aa2ff;
    margin-right: 5px;
    position: relative;
    top: 1px;
  }
}
</style>