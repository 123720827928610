<template>
  <div id="chooselink">
    <el-dialog title="链接选择器" center :visible.sync="chooseShow" :modal-append-to-body="false" :append-to-body="false" :close-on-click-modal="false" width="980px" :show-close="false">
      <div>
        <div class="linkheadbox">
          <xsk-tabs :tabValue.sync="type" :options="options" tabStyle="font-weight: normal;font-size:15px;padding-bottom:4px;border-width:2px !important;" @confirm="tabConfirm"></xsk-tabs>
        </div>
        <div class="contentbox">
          <div class="pagebox" v-if="type==1">
            <div class="page" v-for="(item,index) in linkList" :key="index">
              <div class="title">{{item.title}}</div>
              <div class="list">
                <div class="box" :class="{'active':choose_item.data.id==page.id&&choose_item.type=='page'}" v-for="(page,i) in item.child" :key="i" @click="chooseLick('page',page)">{{page.name}}</div>
              </div>
            </div>
          </div>

          <div class="goodsbox" v-if="type==2">
            <div class="goodheadbox">
              <el-form :inline="true">
                <el-form-item label="商品名称">
                  <el-input v-model="goodkeyword" placeholder="商品名称"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" size="small" @click="goodChangepage(1)">查询</el-button>
                </el-form-item>
              </el-form>
            </div>

            <div class="dialogtabel">
              <el-table v-loading="goodloading" :data="goodslist" style="width: 100%" stripe :header-cell-style="{ background: '#fafafa', color: '#999' }">
                <el-table-column label="商品" width="80">
                  <template slot-scope="scope">
                    <el-image style="width: 58px;height: 58px;" :src="scope.row.image" fit="cover"></el-image>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="">
                  <template slot-scope="scope">
                    <span class="name">{{scope.row.name}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="价格" width="160" align="center">
                  <template slot-scope="scope">
                    <div v-if="scope.row[isSpec]">￥{{scope.row.min_price+'~￥'+scope.row.max_price}}</div>
                    <div v-else>￥{{scope.row[price]}}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="total_stock" label="库存" width="160" align="center"></el-table-column>
                <el-table-column label="操作" width="80" align="center">
                  <template slot-scope="scope">
                    <div class="choosebtn choose not-select" @click="chooseLick('good',scope.row)" v-if="choose_item.type=='good'&&choose_item.data.id==scope.row.id">已选</div>
                    <div class="choosebtn not-select" v-else @click="chooseLick('good',scope.row)">选择</div>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <div class="pages" v-if="goodtotal > 5">
              <el-pagination background :hide-on-single-page="true" @current-change="goodChangepage" :current-page="goodpage" :page-size="5" layout="total, prev, pager, next, jumper" :total="goodtotal"></el-pagination>
            </div>
          </div>

          <div class="goodsbox" v-if="type==5">
            <div class="goodheadbox">
              <el-form :inline="true">
                <el-form-item label="页面名称">
                  <el-input v-model="goodkeyword" placeholder="页面名称"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" size="small" @click="goodChangepage(1)">查询</el-button>
                </el-form-item>
              </el-form>
            </div>

            <div class="dialogtabel">
              <el-table v-loading="diyLoading" :data="diyList" style="width: 100%" stripe :header-cell-style="{ background: '#fafafa', color: '#999' }">
                <el-table-column label="页面名称">
                  <template slot-scope="scope">
                    <span>{{scope.row.name}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="创建时间" prop="created_at" width="260" align="center">
                  <template slot-scope="scope">
                    <span>{{scope.row.created_at}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="80" align="center">
                  <template slot-scope="scope">
                    <div class="choosebtn choose not-select" @click="chooseLick('diypage',scope.row)" v-if="choose_item.type=='diypage'&&choose_item.data.id==scope.row.id">已选</div>
                    <div class="choosebtn not-select" v-else @click="chooseLick('diypage',scope.row)">选择</div>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <div class="pages" v-if="diyTotal > 5">
              <el-pagination background :hide-on-single-page="true" @current-change="goodChangepage" :current-page="diyPage" :page-size="5" layout="total, prev, pager, next, jumper" :total="diyTotal"></el-pagination>
            </div>
          </div>

          <div v-if="type==6">
            <div class="header classbox">
              <div class="headbox">分类名称</div>
              <div class="imgsbox">图标</div>
              <div class="timebox">创建时间</div>
              <div class="operate">操作</div>
            </div>

            <div v-for="(item, index) of classlist" :key="index">
              <!-- 一级分类 -->
              <div class="classbox">
                <div class="headbox">
                  <div class="levels">一级</div>
                  <el-input class="inputbox" v-model="item.name" disabled placeholder="请输入分类名称"></el-input>
                </div>
                <div class="imgsbox">
                  <div class="imgborder" v-if="item.image">
                    <el-image class="img" :src="item.image" fit="cover"></el-image>
                  </div>
                </div>
                <div class="timebox">{{ item.created_at || '-' }}</div>
                <div class="operate">
                  <div class="btnsty" :class="{'active':choose_item.type=='classify'&&choose_item.data.id==item.id}" @click="chooseLick('classify',item)">{{choose_item.type=='classify'&&choose_item.data.id==item.id?'已选':'选择'}}</div>
                </div>
              </div>

              <!-- 二级分类 -->
              <template v-if="setting_level>1">
                <div v-for="(child, i) in item.child" :key="i">
                  <div class="classbox childbox">
                    <div class="headbox" style="padding-left: 40px;">
                      <div class="icon-box">
                        <div class="codeimgs"></div>
                      </div>

                      <div class="levels">二级</div>
                      <el-input class="inputbox" v-model="child.name" disabled placeholder="请输入子分类名称"></el-input>
                    </div>
                    <div class="imgsbox">
                      <div class="imgborder" v-if="child.image">
                        <el-image class="img" :src="child.image" fit="cover"></el-image>
                      </div>
                    </div>
                    <div class="timebox">{{ child.created_at || '-' }}</div>
                    <div class="operate">
                      <div class="btnsty" :class="{'active':choose_item.type=='classify'&&choose_item.data.id==child.id}" @click="chooseLick('classify',child)">{{choose_item.type=='classify'&&choose_item.data.id==child.id?'已选':'选择'}}</div>
                    </div>
                  </div>

                  <template v-if="setting_level>2">
                    <div class="classbox childbox" v-for="(tab, x) in child.child" :key="x">
                      <div class="headbox" style="padding-left: 80px;">
                        <div class="icon-box">
                          <div class="codeimgs"></div>
                        </div>
                        <div class="levels">三级</div>
                        <el-input class="inputbox" v-model="tab.name" disabled placeholder="请输入子分类名称"></el-input>
                      </div>
                      <div class="imgsbox">
                        <div class="imgborder" v-if="tab.image">
                          <el-image class="img" :src="tab.image" fit="cover"></el-image>
                        </div>
                      </div>
                      <div class="timebox">{{ tab.created_at || '-' }}</div>
                      <div class="operate">
                        <div class="btnsty" :class="{'active':choose_item.type=='classify'&&choose_item.data.id==tab.id}" @click="chooseLick('classify',tab)">{{choose_item.type=='classify'&&choose_item.data.id==tab.id?'已选':'选择'}}</div>
                      </div>
                    </div>
                  </template>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { eltips } from '@/util/util.js';
import { shoppingLinkArr } from './shoppinglink.js'
export default {
  name: 'chooselink',
  data() {
    return {
      type: 1,
      options: [
        { label: '商城页面', value: 1 },
        { label: '商品', value: 2 },
        { label: '直播间选择器', value: 3 },
        { label: '优惠券', value: 4 },
        { label: '自定义页面', value: 5 },
        { label: '商品分类', value: 6 },
        { label: '小程序跳转', value: 7 },
        { label: '商户', value: 8 },
      ],
      //页面选择
      linkList: shoppingLinkArr,
      //商品选择
      goodkeyword: '',
      goodloading: false,
      goodslist: [],
      goodpage: 1,
      goodtotal: 0,
      diyLoading: false,
      diyKeyword: '',
      diyPage: 1,
      diyTotal: 0,
      diyList: [],
      classlist: [],
      choose_item: {
        type: '',
        data: '',
      },
      setting_level: 1,
    };
  },
  props: {
    chooseShow: {
      type: Boolean,
      default: false
    },
    isSpec: {
      type: String,
      default: 'erp_goods.is_spec'
    },
    price: {
      type: String,
      default: 'erp_goods.retail_price'
    }
  },
  watch: {
    chooseShow() {
      this.choose_item = {
        type: '',
        data: '',
      }
    }
  },
  created() {
    this.getSetting()
  },
  methods: {
    confirm() {
      if (!this.choose_item.type || !this.choose_item.data) return eltips('请选择跳转的链接', 'error');
      this.$emit('confirm', this.choose_item)
      this.$emit('update:chooseShow', false)
    },
    getSetting() {
      let url = '/api/store/goods/category_settings'

      let userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
      if (userinfo.roles.length > 0) {
        if (userinfo.roles[0].id == 97 || userinfo.roles[0].id == 92) {
          url = '/api/ecshop/goods/category_settings'
        }
      }

      this.axios.get(url).then(res => {
        if (res.code == 200) {
          this.setting_level = res.data.level
        } else {
          eltips(res.msg, 'error')
        }
      })
    },
    //切换tab
    tabConfirm(e) {
      if (e == 2) {
        this.goodpage = 1
        this.getgoodslist();
      }
      if (e == 5) {
        this.diyPage = 1
        this.getPageList();
      }
      if (e == 6) {
        this.getClasslist()
      }
    },
    //关闭组件
    close() {
      this.$emit('update:chooseShow', false)
    },
    //选择链接
    chooseLick(type, item) {
      if (type == this.choose_item.type && this.choose_item.data.id == item.id) {
        this.choose_item = {
          type: '',
          data: '',
        }
      } else {
        this.choose_item = {
          type: type,
          data: item,
        }
      }
    },
    //商品分页、搜索
    goodChangepage(e) {
      this.goodpage = e
      this.getgoodslist()
    },
    //获取商品列表
    getgoodslist() {
      let data = {
        page: this.goodpage,
        name: this.goodkeyword,
        status: 1,
        limit: 5,
      };
      this.goodloading = true;

      let url = '/api/store/goods/list'

      let userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
      if (userinfo.roles.length > 0) {
        if (userinfo.roles[0].id == 97 || userinfo.roles[0].id == 92) {
          url = '/api/ecshop/goods/list'
        }
      }


      this.axios.get(url, { params: data }).then(res => {
        this.goodloading = false;
        if (res.code == 200) {
          this.goodtotal = res.data.total;
          this.goodslist = res.data.data;
        } else {
          eltips(res.msg, 'error')
        }
      })
    },
    //获取自定义页面
    getPageList() {
      let data = {
        keyword: this.diyKeyword,
        page: this.diyPage,
        status: 1,
        type: 4
      }
      this.diyLoading = true
      this.axios.get('/api/store/shop/page', { params: data }).then(res => {
        console.log('%c [ res ]-349', 'font-size:13px; background:#f3ee33; color:#ffff77;', res)
        this.diyLoading = false
        this.diyTotal = res.data.total
        this.diyList = res.data.data
      })
    },
    //获取分类列表
    getClasslist() {

      let url = '/api/store/goods/category'

      let userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
      if (userinfo.roles.length > 0) {
        if (userinfo.roles[0].id == 97 || userinfo.roles[0].id == 92) {
          url = '/api/ecshop/goods/category'
        }
      }

      this.axios.get(url, { status: 1 }).then(res => {
        this.classlist = res.data
      })
    }
  }
};
</script>

<style>
#chooselink .el-dialog__header {
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid #e8eaec;
}
#chooselink .el-dialog--center .el-dialog__body {
  padding: 10px 0;
}
#chooselink .el-dialog__footer {
  border-top: 1px solid #e8eaec;
  padding-bottom: 10px;
}
</style>
<style scoped lang="less">
.linkheadbox {
  border-bottom: 1px solid #e8eaec;
  padding: 0 25px;
}
.contentbox {
  max-height: calc(100vh - 160px - 160px - 44px);
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 25px;
}
.pagebox {
  margin-top: 20px;
  .page {
    .title {
      font-size: 14px;
      font-weight: bold;
      color: #000000;
      margin-bottom: 20px;
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      .box {
        margin-right: 10px;
        flex: 0 0 90px;
        padding: 10px 0;
        width: 90px;
        border: 1px solid #e9edef;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        cursor: pointer;
      }
      .active {
        background: #2d8cf0;
        color: #ffffff;
        border-color: #2d8cf0;
      }
    }
  }
}

.goodsbox {
  .goodheadbox {
    padding-top: 20px;
  }
  .dialogtabel {
    .icon {
      display: inline-block;
      vertical-align: middle;
      font-size: 12px;
      width: 20px;
      line-height: 20px;
      text-align: center;
      margin-right: 6px;
    }
    .xu {
      background-color: #e6fff9;
      color: #00c5c5;
    }
    .shi {
      background-color: #f0faff;
      color: #2d8cf0;
    }
    .name {
      width: 394px;
      vertical-align: top;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .choosebtn {
      width: 60px;
      line-height: 32px;
      border-radius: 2px;
      color: #2d8cf0;
      border: 1px solid #c3c3c3;
      cursor: pointer;
      &:hover {
        border-color: #2d8cf0;
      }
    }
    .choose {
      background: #2d8cf0;
      color: #ffffff;
      border-color: #2d8cf0;
    }
  }
}

.pages {
  text-align: center;
  background: #ffffff;
  padding: 10px 0;
  border-top: 1px solid #ededed;
}

.classbox {
  width: 100%;
  background: #f4f6f8;
  border: 1px solid #e9edef;
  margin-top: 10px;
  border-radius: 2px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;

  .headbox {
    display: flex;
    flex: 0 0 400px;
    justify-content: left;
    align-items: center;
    padding: 0 30px;

    .icon-box {
      margin-right: 15px;
      font-size: 22px;
      color: #999;
      cursor: pointer;
      padding: 0 6px;

      &:hover {
        color: #1e92ff;
      }

      .codeimgs {
        width: 14px;
        height: 14px;
        border-left: 1px solid #999;
        border-bottom: 1px solid #999;
        position: relative;
        top: -4px;
      }
    }

    .levels {
      margin-right: 20px;
    }

    .inputbox {
      width: 150px;
      margin-right: 20px;
    }

    .addbtn {
      color: #1e92ff;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .imgsbox {
    flex: 0 0 160px;
    display: flex;
    justify-content: center;
    align-items: center;

    .imgborder {
      width: 60px;
      height: 60px;
      position: relative;

      &:hover {
        .meng {
          opacity: 1;
        }
      }

      .img {
        width: 100%;
        height: 100%;
      }

      .meng {
        cursor: pointer;
        z-index: 1;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 28px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        color: #ffffff;
      }
    }

    .addbox {
      border: 1px dashed #e9edef;
      width: 60px;
      height: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: #fff;

      .addicon {
        font-size: 28px;
        color: #1989fa;
      }

      .text {
        font-size: 12px;
        color: #999;
        line-height: 12px;
      }
    }
  }

  .timebox {
    flex: 2;
  }

  .statusbox {
    flex: 2;
  }

  .operate {
    flex: 0 0 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    .btnsty {
      width: 80px;
      padding: 6px 0;
      border: 1px solid #c3c3c3;
      border-radius: 2px;
      cursor: pointer;
      color: #2d8cf0;
    }
    .active {
      background: #2d8cf0;
      color: #ffffff;
      border-color: #2d8cf0;
    }
  }
}

.childbox {
  margin-top: 0;
  border-top: none;
}
</style>
