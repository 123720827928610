<template>
  <div class="orderContent"
    :style="{'margin':plugins_data.style.margin_top/2+'px '+ plugins_data.style.margin_left/2+'px','borderRadius': plugins_data.style.border_radius_top/2+'px ' + plugins_data.style.border_radius_top/2+'px ' + plugins_data.style.border_radius_bottom/2+'px ' + plugins_data.style.border_radius_bottom/2+'px'}">
    <div class="headbox">
      <div class="title">我的订单</div>
      <div class="rightbox">
        <div class="text">查看全部</div>
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
    <div class="btnbox">
      <div class="box" v-for="(item,key) in plugins_data.menus" :key="key">
        <div class="badge"></div>
        <img class="icon" :src="item.url||item.defaultUrl" />
        <div class="text">{{item.text}}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      plugins_data: {
        type: Object
      },
    },
    watch: {
      plugins_data: {
        //监听的对象
        deep: true, //深度监听设置为 true
        handler: function (newV, oldV) {
          console.log("watch中：", newV);
        },
      },
    }
  };
</script>

<style lang="scss" scoped>
  .orderContent {
    padding: 15px 18px 0;
    background-color: #ffffff;

    .headbox {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font-size: 14px;
        color: #333333;
        font-weight: bold;
      }

      .rightbox {
        display: flex;
        align-items: center;

        .text {
          font-size: 12px;
          line-height: 12px;
          color: #333333;
        }

        .el-icon-arrow-right {
          font-size: 16px;
          color: #333333;
        }
      }
    }

    .btnbox {
      margin-top: 22px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;

      .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        .badge {
          position: absolute;
          left: 19px;
          top: -7px;
        }

        .icon {
          width: 29px;
          height: 29px;
          display: block;
        }

        .text {
          margin-top: 4px;
          color: #333333;
          font-size: 12px;
        }
      }
    }
  }
</style>