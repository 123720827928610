//全局组件注册
import goodsgroup_view from './view/baseplugins/goodsgroup.vue' //商品组view
import goodsgroup_data from './data/baseplugins/goodsgroup.vue' //商品组data
import tabs_view from './view/baseplugins/tabs.vue' //标签页view
import tabs_data from './data/baseplugins/tabs.vue' //标签页data
import titles_view from './view/baseplugins/titles.vue' //标题栏view
import titles_data from './data/baseplugins/titles.vue' //标题栏data
import search_view from './view/baseplugins/search.vue' //搜索栏view
import search_data from './data/baseplugins/search.vue' //搜索栏data
import swiper_view from './view/baseplugins/swiper.vue' //图片轮播view
import swiper_data from './data/baseplugins/swiper.vue' //图片轮播data
import picture_view from './view/baseplugins/picture.vue' //单图组view
import picture_data from './data/baseplugins/picture.vue' //单图组data
import menus_view from './view/baseplugins/menus.vue' //按钮组view
import menus_data from './data/baseplugins/menus.vue' //按钮组data
import guide_view from './view/baseplugins/guide.vue' //辅助线view
import guide_data from './data/baseplugins/guide.vue' //辅助线data
import richtext_view from './view/baseplugins/richtext.vue' //富文本view
import richtext_data from './data/baseplugins/richtext.vue' //富文本data
import magic_view from './view/baseplugins/magic.vue' //魔方view
import magic_data from './data/baseplugins/magic.vue' //魔方data
import video_view from './view/baseplugins/video.vue' //视频组件view
import video_data from './data/baseplugins/video.vue' //视频组件data
import graphic_view from './view/baseplugins/graphic.vue' //图文列表view
import graphic_data from './data/baseplugins/graphic.vue' //图文列表data
import notice_view from './view/baseplugins/notice.vue' //公告view
import notice_data from './data/baseplugins/notice.vue' //公告data
import business_view from './view/functionplugins/business.vue' //公告data
import business_data from './data/functionplugins/business.vue' //公告data
import hotarea_view from './view/baseplugins/hotarea.vue' //热区view
import hotarea_data from './data/baseplugins/hotarea.vue' //热区data

import information_view from './view/baseplugins/information.vue' //商家信息view
import information_data from './data/baseplugins/information.vue' //商家信息data

import vip_view from './view/baseplugins/vip.vue' //个人中心view
import vip_data from './data/baseplugins/vip.vue' //个人中心data
import order_view from './view/baseplugins/order.vue' //个人中心view
import order_data from './data/baseplugins/order.vue' //个人中心data
import function_view from './view/baseplugins/function.vue' //功能中心view
import function_data from './data/baseplugins/function.vue' //功能中心data


function storeplugins(Vue){
	Vue.component("goodsgroup-view",goodsgroup_view)
	Vue.component("goodsgroup-data",goodsgroup_data)
	Vue.component("tabs-view",tabs_view)
	Vue.component("tabs-data",tabs_data)
	Vue.component("titles-view",titles_view)
	Vue.component("titles-data",titles_data)
	Vue.component("search-view",search_view)
	Vue.component("search-data",search_data)
	Vue.component("swiper-view",swiper_view)
	Vue.component("swiper-data",swiper_data)
	Vue.component("picture-view",picture_view)
	Vue.component("picture-data",picture_data)
	Vue.component("menus-view",menus_view)
	Vue.component("menus-data",menus_data)
	Vue.component("guide-view",guide_view)
	Vue.component("guide-data",guide_data)
	Vue.component("richtext-view",richtext_view)
	Vue.component("richtext-data",richtext_data)
	Vue.component("magic-view",magic_view)
	Vue.component("magic-data",magic_data)
	Vue.component("video-view",video_view)
	Vue.component("video-data",video_data)
	Vue.component("graphic-view",graphic_view)
	Vue.component("graphic-data",graphic_data)
	Vue.component("notice-view",notice_view)
	Vue.component("notice-data",notice_data)
	Vue.component("business-view",business_view)
	Vue.component("business-data",business_data)
	Vue.component("hotarea-view",hotarea_view)
	Vue.component("hotarea-data",hotarea_data)
	
	Vue.component("information-view",information_view)
	Vue.component("information-data",information_data)

	Vue.component("vip-view",vip_view)
	Vue.component("vip-data",vip_data)
	Vue.component("order-view",order_view)
	Vue.component("order-data",order_data)
	Vue.component("functions-view",function_view)
	Vue.component("functions-data",function_data)
	
}

export default storeplugins