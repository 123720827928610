<template>
  <div class="plugins">
    <div>
      <div class="headsty">内容</div>
      <div class="listbox">
        <draggable v-model="plugins_data.menus" :options="{ animation: 200 }">
          <div class="list" v-for="(item,index) in plugins_data.menus" :key="index">
            <!-- <i class="el-icon-error delicon" @click="delMenus(index)" v-if="plugins_data.menus.length>1"></i> -->
            <div class="left">
              <i class="el-icon-s-operation"></i>
            </div>
            <div class="right">
              <div class="box flexstart">
                <div class="label">按钮图片:</div>
                <div class="uploadbox">
                  <div class="imgbox" @click="openManage(index)">
                    <i v-if="item.url" class="el-icon-error delimg" @click.stop="delUrl(index)"></i>
                    <el-image class="image" v-if="item.url" :src="item.url" fit="cover"></el-image>
                    <i v-else class="el-icon-plus"></i>
                  </div>
                  <div class="tips">建议图片尺寸200*200，比例为1:1</div>
                </div>
              </div>
              <div class="box">
                <div class="label">按钮文字:</div>
                <el-input v-model="item.text" placeholder="请输入按钮文字" maxlength="5" show-word-limit></el-input>
              </div>
              <!-- <div class="box">
                <div class="label">跳转链接:</div>
                <div class="linkbox">
                  <el-input v-if="item.link" v-model="item.text" style="color: #333;!important">
                    <div slot="prepend"><i class="el-icon-link linkIcon"></i></div>
                    <div slot="append"><span class="changebtn" @click="openLink(index,item.link)">修改</span></div>
                  </el-input>
                  <div class="addlink" v-else @click="openLink(index)"><i class="el-icon-link"></i> 选择链接</div>
                </div>
              </div> -->
            </div>
          </div>
        </draggable>
        <!-- <div class="addbtn" @click="addmenus" v-if="plugins_data.menus.length<5">+添加按钮</div> -->
      </div>
      
      <div class="linesty"></div>
      <div class="box">
        <div class="label">上边距:</div>
        <el-slider class="sliderstyle" v-model="plugins_data.style.margin_top" :max="50"></el-slider>
        <el-input class="styleinput" type="number" v-model="plugins_data.style.margin_top">
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">左右边距:</div>
        <el-slider class="sliderstyle" v-model="plugins_data.style.margin_left" :max="50"></el-slider>
        <el-input class="styleinput" type="number" v-model="plugins_data.style.margin_left">
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">下边距:</div>
        <el-slider class="sliderstyle" v-model="plugins_data.style.margin_bottom" :max="50"></el-slider>
        <el-input class="styleinput" type="number" v-model="plugins_data.style.margin_bottom">
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">上边角:</div>
        <el-slider class="sliderstyle" v-model="plugins_data.style.border_radius_top" :max="50"></el-slider>
        <el-input class="styleinput" type="number" v-model="plugins_data.style.border_radius_top">
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">下边角:</div>
        <el-slider class="sliderstyle" v-model="plugins_data.style.border_radius_bottom" :max="50"></el-slider>
        <el-input class="styleinput" type="number" v-model="plugins_data.style.border_radius_bottom">
          <template slot="append">PX</template>
        </el-input>
      </div>
    </div>
    <chooselink :chooseShow.sync="chooseShow" @confirm="confirm"></chooselink>
    <xsk-manage :managehide.sync="imgshow" is_rest @confirm="pickimg"></xsk-manage>
  </div>
</template>

<script>
  import draggable from 'vuedraggable';
  import chooselink from '../../common/chooselink.vue'
  export default {
    name: 'meuns',
    components: {
      draggable,
      chooselink
    },
    data() {
      return {
        imgshow: false,
        pickIndex: -1,
        chooseShow: false,
        index: '',
      };
    },
    props: {
      plugins_data: {
        type: Object
      }
    },
    methods: {
      openLink(index, item) {
        this.index = index
        this.chooseShow = true
      },
      confirm(e) {
        let item = this.plugins_data.menus[this.index]
        item.text = e.data.name
        item.link = e.data.path
        // 不提交自定义页面的content  thunmbnail
        if (item.link.type == "diypage") {
          item.link.data.content = null
          item.link.data.thumbnail = null
        }
        this.$set(this.plugins_data.menus, this.index, item)
      },
      //选择个数
      radioChange(e) {
        if (this.plugins_data.menus.length < e) {
          this.plugins_data.menus.push({
            url: '',
            text: '按钮文字' + (this.plugins_data.menus.length + 1),
            link: null
          })
          this.radioChange(e)
        } else {
          return
        }
      },
      //添加按钮
      addmenus() {
        this.plugins_data.menus.push({
          url: '',
          text: '按钮文字' + (this.plugins_data.menus.length + 1),
          link: null
        })
      },
      //删除按钮
      delMenus(index) {
        this.plugins_data.menus.splice(index, 1)
      },
      // 删除图片
      delUrl(index) {
        let item = this.plugins_data.menus[index]
        item.url = ''
        this.$set(this.plugins_data.menus, index, item)
      },
      //打开图片管理器
      openManage(index) {
        this.pickIndex = index
        this.imgshow = true
      },
      //选择图片
      pickimg(e) {
        let item = this.plugins_data.menus[this.pickIndex]
        item.url = e.imgobjs[0].qiniu_url
        this.$set(this.plugins_data.menus, this.pickIndex, item)
      },
    }
  };
</script>

<style scoped lang="less">
  @import "../../style/pluginsCommon.css";

  .shapebox {
    display: flex;

    .radiobox {
      padding: 4px 20px;
      border: 1px solid #cccccc;
      cursor: pointer;

      &:first-child {
        border-right: none;
      }

      .menustyle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        background: #939799;
        color: #ffffff;
        font-size: 14px;
      }

      .cir {
        border-radius: 50%;
      }

      .squ {
        border-radius: 4px;
      }
    }

    .active {
      border-right: 1px solid #1989fa !important;
      border: 1px solid #1989fa;

      .menustyle {
        background: #1989fa !important;
      }
    }
  }

  .listbox {
    margin: 10px 0 20px;

    .list {
      padding: 15px;
      background: #f4f6f8;
      border: 1px solid #e9edef;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      position: relative;

      &:last-child {
        margin-bottom: 0;
      }

      .delicon {
        font-size: 20px;
        color: rgba(0, 0, 0, 0.3);
        position: absolute;
        right: -10px;
        top: -10px;
        display: none;
        cursor: pointer;
      }

      &:hover {
        .delicon {
          display: block;
        }
      }

      .left {
        flex: 0 0 30px;
        font-size: 20px;
        color: #b8b9bd;
      }

      .right {
        flex: 1;

        .box {
          display: flex;
          align-items: center !important;

          &:last-child {
            margin-bottom: 0;
          }

          .label {
            flex: 0 0 70px;
            width: 70px;
          }
        }

        .flexstart {
          align-items: flex-start;
        }

        .uploadbox {
          display: flex;
          align-items: center;

          .imgbox {
            margin-right: 10px;
            width: 40px;
            height: 40px;
            border: 1px solid #e9edef;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-size: 20px;
            color: #1989fa;
            cursor: pointer;
            background: #ffffff;
            position: relative;

            .image {
              width: 38px;
              height: 38px;
            }

            .delimg {
              font-size: 20px;
              color: rgba(0, 0, 0, 0.3);
              position: absolute;
              right: -10px;
              top: -10px;
              z-index: 9;
              display: none;
              cursor: pointer;
            }

            &:hover {
              .delimg {
                display: block;
              }
            }
          }

          .tips {
            margin-top: 6px;
            color: #939799;
            font-size: 12px;
          }
        }

        .linkbox {
          .addlink {
            height: 32px;
            line-height: 32px;
            border: 1px solid #dcdee2;
            color: #409eff;
            padding: 0 10px;
            box-sizing: border-box;
            border-radius: 4px;
            cursor: pointer;
            background: #ffffff;

            &:hover {
              border-color: #409eff;
            }
          }

          .tips {
            color: #999999;
            font-size: 12px;
          }

          .changebtn {
            color: #409eff;
            cursor: pointer;
          }

          .linkIcon {
            color: #409eff;
            font-size: 16px;
          }
        }
      }
    }
  }

  .addbtn {
    line-height: 32px;
    text-align: center;
    border: 1px solid #dcdee2;
    color: #409eff;
    margin-bottom: 20px;

    &:hover {
      border-color: #409eff;
    }
  }
</style>