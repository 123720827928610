<template>
  <div id="recom">
    <!-- <Index api="retail/admin" /> -->
    <keep-alive :include="includeAlive">
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
import { eltips } from "@/util/util.js";
import { mapGetters } from "vuex";
import Index from "@/views/apps/common/index2.vue";
export default {
  name: "recom",
  data() {
    return {
      keep: 0,
    };
  },
  computed: {
    ...mapGetters({
      includeAlive: "getIncludeAlive",
    }),
  },
  components: {
    Index,
  },
  mounted() {},
  methods: {},
};
</script>



<style scoped lang="less">
#recom {
  max-height: calc(100vh - 110px);
  overflow: auto;
}
</style>
