<template>
  <div class='plugins' id='hotareaData'>
    <div>
      <div class="headsty">内容样式</div>
      <div class="box">
        <div class="label">上边距:</div>
        <el-slider class="sliderstyle" v-model="plugins_data.style.margin_top" :max="40"></el-slider>
        <el-input class="styleinput" type="number" v-model.number="plugins_data.style.margin_top">
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">下边距:</div>
        <el-slider class="sliderstyle" v-model="plugins_data.style.margin_bottom" :max="40"></el-slider>
        <el-input class="styleinput" type="number" v-model.number="plugins_data.style.margin_bottom">
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">左右边距:</div>
        <el-slider class="sliderstyle" v-model="plugins_data.style.margin_left" :max="40"></el-slider>
        <el-input class="styleinput" type="number" v-model.number="plugins_data.style.margin_left">
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">上边角:</div>
        <el-slider class="sliderstyle" v-model="plugins_data.style.border_radius_top" :max="40"></el-slider>
        <el-input class="styleinput" type="number" v-model.number="plugins_data.style.border_radius_top">
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">下边角:</div>
        <el-slider class="sliderstyle" v-model="plugins_data.style.border_radius_bottom" :max="40"></el-slider>
        <el-input class="styleinput" type="number" v-model.number="plugins_data.style.border_radius_bottom">
          <template slot="append">PX</template>
        </el-input>
      </div>
    </div>

    <div>
      <div class="headsty">内容</div>
      <div class="box" style="align-items: flex-start;">
        <div class="label">热区图片:</div>
        <div class="contentbox">
          <div class="tips" v-if="!plugins_data.data.image">建议图片宽度750像素, 高度400像素</div>
          <div class="tipbox" v-else>点击图片打开热区编辑器</div>
          <div class="imgbox" v-if="plugins_data.data.image" @click="openEdit">
            <!-- <el-image class="img" :src="plugins_data.data.image"></el-image> -->
            <el-image class="img" ref="dataimg" :src="plugins_data.data.image" @load="dataimgload"></el-image>
            <div class="box" v-for="item in plugins_data.data.list" :key="item.key" :style="{
							'width':item.width*(nowWidth/plugins_data.data.initWidth)+'px',
							'height':item.height*(nowHeight/plugins_data.data.initHeight)+'px',
							'top':item.top*(nowHeight/plugins_data.data.initHeight)+'px',
							'left':item.left*(nowWidth/plugins_data.data.initWidth)+'px',
						}">
              <div class="text">{{item.link?item.link.data.name:'选择链接'}}</div>
            </div>
          </div>
          <div class="addbtn" @click="imgshow=true">{{plugins_data.data.image?'替换':'+添加'}}图片</div>
        </div>

      </div>
    </div>

    <xsk-manage :managehide.sync="imgshow" is_rest @confirm="pickimg"></xsk-manage>

    <el-dialog title="热区编辑器" :visible.sync="editShow" center width="790px" :modal-append-to-body="false" :append-to-body="false" style="z-index: 9999;">
      <!-- append-to-body -->
      <!-- :close-on-click-modal="false" -->
      <!-- :close-on-press-escape="false" -->
      <div>
        <div class="hotareabox">
          <div>
            <!-- <img  ref="imgSize" class="img" :src="editForm.image" @load="imgload"> -->
            <el-image class="img" ref="elimg" :src="editForm.image" @load="imgload"></el-image>
            <template v-if="!imgLoading">
              <vue-drag-resize :isActive="isActive==index" v-for="(item,index) in editForm.list" :key="item.key" parentLimitation :w="item.width" :h="item.height" :x="item.left" :y="item.top" @resizing="resize($event,index)" @resizestop="isActive=index" @dragstop="isActive=index" @dragging="resize($event,index)">
                <div class="box" :style="{'width':item.width+'px','height':item.height+'px'}">
                  <div class="hotaddbtn" @click="openLink(index)">{{item.link?'修改':'添加'}}链接</div>
                  <div class="text" v-if="item.link">{{item.link.data.name}}</div>
                  <i class="el-icon-delete-solid icon" v-if="isActive==index" @click="delHot(index)"></i>
                </div>
              </vue-drag-resize>
            </template>
          </div>

        </div>
      </div>

      <span slot="footer">
        <el-button @click="saveHot" style="margin-right: 28px;">保存修改</el-button>
        <el-button type="primary" @click="addHot">添加热区</el-button>
      </span>
    </el-dialog>

    <chooselink :chooseShow.sync="chooseShow" @confirm="confirm"></chooselink>
  </div>
</template>

<script>
import { eltips } from '@/util/util.js';
import VueDragResize from 'vue-drag-resize';
import chooselink from '../../common/chooselink.vue'
export default {
  name: 'hotareaData',
  components: { VueDragResize, chooselink },
  data() {
    return {
      imgshow: false,
      editShow: false,
      editForm: {
        image: '',
        initHeight: '',
        initWidth: '',
        list: [],
      },
      imgLoading: true,
      isActive: -1,
      chooseShow: false,
      linkIndex: -1,
      nowHeight: 1,
      nowWidth: 1,
    };
  },
  props: {
    plugins_data: { type: Object },
  },
  methods: {
    openLink(index) {
      this.linkIndex = index
      this.chooseShow = true
    },
    confirm(e) {
      let item = this.editForm.list[this.linkIndex]
      item.link = e
      // 不提交自定义页面的content  thunmbnail
      if (item.link.type == "diypage") {
        item.link.data.content = null
        item.link.data.thumbnail = null
      }
      this.$set(this.editForm.list, this.linkIndex, item)
    },
    delHot(index) {
      this.editForm.list.splice(this.isActive, 1)
      this.isActive = -1
    },
    dataimgload(e) {
      this.nowHeight = this.$refs.dataimg.$el.offsetHeight
      this.nowWidth = this.$refs.dataimg.$el.offsetWidth
    },
    imgload(e) {
      this.editForm.initHeight = this.$refs.elimg.$el.offsetHeight
      this.editForm.initWidth = this.$refs.elimg.$el.offsetWidth
    },
    addHot() {
      this.isActive = this.editForm.list.length
      this.editForm.list.push({ left: 0, top: 0, width: 100, height: 100, link: '', key: (this.editForm.list.length + Math.random()) })
    },
    resize(e, index) {
      this.isActive = index
      let item = this.editForm.list[index]
      item.left = e.left
      item.top = e.top
      item.width = e.width
      item.height = e.height
      this.$set(this.editForm.list, index, item)
    },
    openEdit() {
      this.imgLoading = true
      this.editForm = JSON.parse(JSON.stringify(this.plugins_data.data))
      this.isActive = -1
      this.editShow = true
      setTimeout(() => {
        this.imgLoading = false
      }, 100)
    },
    saveHot() {
      this.plugins_data.data = JSON.parse(JSON.stringify(this.editForm))
      this.editShow = false
    },
    //选择图片
    pickimg(e) {
      if (e.imgobjs.length > 0) {
        this.plugins_data.data.image = e.imgobjs[0].qiniu_url
      }
    },
  }
};
</script>

<style lang="less" scoped>
@import "../../style/pluginsCommon.css";

.contentbox {
  flex: 1;
  .tips {
    font-size: 14px;
    color: #939799;
  }
  .tipbox {
    padding: 7px 9px;
    border-radius: 2px;
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    border: 1px solid #abdcff;
    background-color: #f0faff;
    cursor: default;
  }
}

.imgbox {
  position: relative;
  margin-top: 10px;
  .img {
    width: 100%;
    height: auto;
    display: block;
  }
  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    padding: 5px;
    background: rgba(45, 140, 240, 0.6);
    cursor: default;
    overflow: hidden;
    .text {
      font-size: 12px;
      color: #ffffff;
    }
  }
}

.addbtn {
  margin-top: 10px;
  font-size: 14px;
  line-height: 32px;
  border-radius: 2px;
  color: #262b30;
  border: 1px solid #dcdee2;
  text-align: center;
  cursor: pointer;
  &:hover {
    border-color: #409eff;
  }
}

.hotareabox {
  width: 750px;
  position: relative;
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: scroll;
  .img {
    width: 100%;
    height: auto;
    display: block;
  }
  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(45, 140, 240, 0.6);
    cursor: default;
    position: relative;
    .icon {
      position: absolute;
      right: -8px;
      top: -8px;
      font-size: 18px;
      color: #c0c0c0;
      z-index: 99999999;
    }
    .hotaddbtn {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 0 8px;
      height: 18px;
      font-size: 12px;
      line-height: 18px;
      color: #ffffff;
      background: rgba(0, 0, 0, 0.7);
      z-index: 9999;
      cursor: pointer;
    }
    .text {
      font-size: 14px;
      color: #ffffff;
    }
  }
}
</style>
