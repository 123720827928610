<template>
  <div>
    <div v-if="$route.meta.layout && $route.meta.layout != 0" style="position: relative">
      <div class="top-menu">
        <topMenu></topMenu>
      </div>
      <div class="main">
        <leftMenu></leftMenu>
        <contentBox :left="left"></contentBox>
      </div>
    </div>
    <router-view v-else />
  </div>
</template>

<script>
  // import headernav from "./components/headernav.vue";
  // import asidenav from "./components/asidenav.vue";
  // import mainbox from "./components/mainbox.vue";
  // import rightbox from "./components/rightbox.vue";
  import topMenu from './components/top-menu.vue';
  import leftMenu from './components/left-menu'
  import contentBox from './components/content-box'
  export default {
    name: "layout",
    components: {
      // headernav, asidenav, mainbox, rightbox
      topMenu,
      leftMenu,
      contentBox
    },
    data() {
      return {
        leftmargin: 100,
        test: 1,
        left: 0
      };
    },
    created() {
      // if (this.$route.matched.length > 1) {
      //   this.leftmargin = 240;
      // } else {
      //   this.leftmargin = 100;
      // }
    },
    watch: {
      "$store.state.asideWidth": function (newflag, oldflag) {
        console.log(newflag, oldflag)
        if (newflag == 50) {
          this.left = 160;
        } else {
          this.left = 0;
        }
      },
    },
    mounted() {},
  };
</script>

<style lang="less" scoped>
  .top-menu {
    width: 100%;
    height: 58px;
    background: #1f2227;
    position: fixed;
    top: 0;
    z-index: 9;

    .main {
      width: 100%;
      position: relative;
    }
  }
</style>