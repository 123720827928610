import {
	Message
} from 'element-ui'

export let httpurl = process.env.NODE_ENV === 'production' ? '' : 'https://eshop.xsksaasyun.com'
// export let httpurl = 'https://zyk.xsksaasyun.cn'
// export let httpurl = 'https://a.sid1688.cn'
export let mapkey = 'TEHBZ-4XQL5-SMGIW-Q3JUW-Y4WPE-QWBA5'
import echarts from 'echarts'
import "../../node_modules/echarts/map/js/china.js";

export function eltips(text, type = 'info') {
	Message({
		message: text,
		type: type,
		center: true,
		offset: 300,
		duration: 2000
	})
}

//复制
export function copylink(link) {
	let url = link;
	let oInput = document.createElement('input');
	oInput.setAttribute('class', 'copy_index')
	document.body.appendChild(oInput)
	oInput.value = url;
	oInput.select(); // 选择对象;
	document.execCommand("Copy"); // 执行浏览器复制命令
	eltips('复制成功', 'success')
}

//笛卡尔积
export function SpecCartesian(arr1, arr2) {
	// 去除第一个元素
	var result = [];
	var temp_arr = arr1;
	var first = temp_arr.splice(0, 1);

	if ((arr2 || null) == null) {
		arr2 = [];
	}


	if (arr2.length > 0) {
		for (var i in arr2) {
			for (var k in first[0].spec_value) {
				result.push(arr2[i] + ',' + first[0].spec_value[k]);
			}
		}
	} else {
		for (var i in first[0].spec_value) {
			result.push(first[0].spec_value[i]);
		}
	}

	// 递归进行拼接
	if (arr1.length > 0) {
		result = SpecCartesian(arr1, result);
	}

	console.log(result)
	// 返回最终笛卡尔积
	return result
}

//笛卡尔积算法
export function descartes(array) {
	if (array.length < 2) return array[0] || [];
	return [].reduce.call(array, function (col, set) {
		var res = [];
		col.forEach(function (c) {
			set.forEach(function (s) {
				var t = [].concat(Array.isArray(c) ? c : [c]);
				t.push(s);
				res.push(t);
			})
		});
		return res;
	});
}

//截取url参数
export function getUrlKey(name, url) {
	return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(url || window.location
		.href) || [, ""])[1].replace(
		/\+/g, '%20')) || null
}

//时间计算
export function formattime(time, time2) {
	if (time < time2) {
		return '时间异常'
	}
	var dateEnd = time; //获取当前时间
	var dateDiff = dateEnd - time2; //时间差的毫秒数
	var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数
	var leave1 = dateDiff % (24 * 3600 * 1000) //计算天数后剩余的毫秒数
	var hours = Math.floor(leave1 / (3600 * 1000)) //计算出小时数
	if (hours < 10) {
		hours = '0' + hours
	}
	//计算相差分钟数
	var leave2 = leave1 % (3600 * 1000) //计算小时数后剩余的毫秒数
	var minutes = Math.floor(leave2 / (60 * 1000)) //计算相差分钟数
	if (minutes < 10) {
		minutes = '0' + minutes
	}
	//计算相差秒数
	var leave3 = leave2 % (60 * 1000) //计算分钟数后剩余的毫秒数
	var seconds = Math.round(leave3 / 1000)
	if (seconds < 10) {
		seconds = '0' + seconds
	}
	var timeFn
	if (dayDiff > 1) {
		timeFn = dayDiff + "天" + hours + ":" + minutes + ":" + seconds;
	} else {
		timeFn = hours + ":" + minutes + ":" + seconds;
	}
	return timeFn;
}

//去除对象或数组中的空值('',null,undefined,[],{})	
export function removeEmptyField(obj) {
	var newObj = {}
	if (typeof obj === 'string') {
		obj = JSON.parse(obj)
	}
	if (obj instanceof Array) {
		newObj = []
	}
	if (obj instanceof Object) {
		for (var attr in obj) {
			// 属性值不为'',null,undefined才加入新对象里面(去掉'',null,undefined)
			if (obj.hasOwnProperty(attr) && obj[attr] !== '' && obj[attr] !== null && obj[attr] !== undefined) {
				if (obj[attr] instanceof Object) {
					// 空数组或空对象不加入新对象(去掉[],{})
					if (JSON.stringify(obj[attr]) === '{}' || JSON.stringify(obj[attr]) === '[]') {
						continue
					}
					// 属性值为对象,则递归执行去除方法
					newObj[attr] = removeEmptyField(obj[attr])
				} else if (
					typeof obj[attr] === 'string' &&
					((obj[attr].indexOf('{') > -1 && obj[attr].indexOf('}') > -1) ||
						(obj[attr].indexOf('[') > -1 && obj[attr].indexOf(']') > -1))
				) {
					// 属性值为JSON时
					try {
						var attrObj = JSON.parse(obj[attr])
						if (attrObj instanceof Object) {
							newObj[attr] = removeEmptyField(attrObj)
						}
					} catch (e) {
						newObj[attr] = obj[attr]
					}
				} else {
					newObj[attr] = obj[attr]
				}
			}
		}
	}
	return newObj
}

export function getdate(time) {
	var now = new Date(time),
		y = now.getFullYear(),
		m = ("0" + (now.getMonth() + 1)).slice(-2),
		d = ("0" + now.getDate()).slice(-2);
	return y + "-" + m + "-" + d + " ";
}

/**
 * 订单状态返回
 */
export function orderStatus(status) {
	let item = {
		value: '',
		color: ''
	}
	switch (status) {
		case 0:
			item.value = '待付款'
			item.color = '#FF1F2C'
			break;
		case 1:
			item.value = '待接单'
			break;
		case 2:
			item.value = '待配送'
			item.color = '#FF9900'
			break;
		case 3:
			item.value = '配送中'
			break;
		case 4:
			item.value = '已送达'
			item.color = '#2D8CF0'
			break;
		case 5:
			item.value = '待自提'
			item.color = '#1E92FF'
			break;
		case 6:
			item.value = '待评价'
			item.color = '#1E92FF'
			break;
		case 7:
			item.value = '已完成'
			item.color = '#1E92FF'
			break;
		case 9:
			item.value = '配送异常'
			break;
		case 10:
			item.value = '已关闭'
			item.color = '#939799'
			break;
	}

	return item;
}

//时间格式化
export function dateFormat(date, format = 'yyyy-MM-dd hh:mm:ss') {
	Date.prototype.Format = function (fmt) {
		var o = {
			'M+': this.getMonth() + 1, // 月份
			'd+': this.getDate(), // 日
			'h+': this.getHours(), // 小时
			'm+': this.getMinutes(), // 分
			's+': this.getSeconds(), // 秒
			'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
			S: this.getMilliseconds() // 毫秒
		};
		if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length));
		for (var k in o)
			if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : (
				'00' + o[k]).substr(
				('' + o[k]).length));
		return fmt;
	};

	return (date || new Date()).Format(format)
}

// 时间格式化去时分秒
export function dateFormatY(date, format) {
	Date.prototype.Format = function (fmt) {
		var o = {
			'M+': this.getMonth() + 1, // 月份
			'd+': this.getDate(), // 日
			'h+': this.getHours(), // 小时
			'm+': this.getMinutes(), // 分
			's+': this.getSeconds(), // 秒
			'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
			S: this.getMilliseconds() // 毫秒
		};
		if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length));
		for (var k in o)
			if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : (
				'00' + o[k]).substr(
				('' + o[k]).length));
		return fmt;
	};

	return new Date(date).Format(format)
}

//echar 折线图
export function setEchartLine(option) {
	let echar = echarts.init(document.getElementById(option.el_id))
	echar.setOption({
		tooltip: {
			trigger: 'axis',
		},
		grid: {
			top: '3%',
			left: '4%',
			right: '3%',
			bottom: '5%',
		},
		xAxis: {
			type: 'category',
			boundaryGap: false,
			axisTick: {
				alignWithLabel: true
			},
			data: option.x_data
		},
		yAxis: {
			type: 'value',
			minInterval: 1,
			splitLine: {
				show: true,
				lineStyle: {
					color: ['#e9edef'],
					width: 1,
					type: 'solid'
				}
			},
		},

		series: [{
			name: '',
			type: 'line',
			smooth: true,
			stack: '数量',
			data: option.y_data,
			symbolSize: 10,
			areaStyle: {
				normal: {
					color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
						offset: 0,
						color: option.lineColor1 || 'rgba(135,154,255,1)' // 0% 处的颜色
					}, {
						offset: 0.5,
						color: option.lineColor2 || 'rgba(135,154,255,.5)' // 50% 处的颜色
					}, {
						offset: 1,
						color: option.lineColor3 || 'rgba(135,154,255,.2)' // 100% 处的颜色
					}])
				}
			},
			itemStyle: {
				normal: {
					color: option.color || '#4a67ff', //改变折线点的颜色
					lineStyle: {
						color: option.color || '#4a67ff' //改变折线颜色
					}
				}
			},
		}]
	})


	setTimeout(() => {
		window.onresize = function () {
			echar.resize();
		}
	}, 200)
}

// echar 折柱混合图
export function setEchartColumnLine(option) {
	let echar = echarts.init(document.getElementById(option.el_id));
	echar.clear();
	echar.setOption({
		tooltip: {
			trigger: 'axis',
		},
		legend: {
			bottom: '0%',
			data: option.legend
		},
		grid: {
			left: '4%',
			right: '3%',
			bottom: '10%',
		},
		xAxis: {
			type: 'category',
			boundaryGap: true,
			data: option.x_data,
			axisTick: {
				alignWithLabel: true
			}
		},
		yAxis: {
			type: 'value',
			minInterval: 1,
			splitLine: {
				show: true,
				lineStyle: {
					color: ['#e9edef'],
					width: 1,
					type: 'solid'
				}
			}
		},
		series: [{
			name: option.legend[0],
			type: 'bar',
			smooth: true,
			stack: '数量',
			data: option.bar_data,
			itemStyle: {
				color: '#67C23A'
			}
		}, {
			name: option.legend[1],
			type: 'line',
			smooth: true,
			stack: '数量',
			data: option.line_data,
			symbolSize: 10,
			itemStyle: {
				normal: {
					color: option.color || '#4a67ff', //改变折线点的颜色
					lineStyle: {
						color: option.color || '#4a67ff' //改变折线颜色
					}
				}
			},
		}]
	})

	setTimeout(() => {
		window.onresize = function () {
			echar.resize();
		}
	}, 200)
}

// echar 柱状图
export function setEchartColumn(option) {
	let echar = echarts.init(document.getElementById(option.el_id));
	echar.clear();
	echar.setOption({
		tooltip: {
			trigger: 'axis',
		},
		legend: {
			bottom: '0%',
			data: option.legend
		},
		grid: {
			left: '4%',
			right: '3%',
			bottom: '10%',
		},
		xAxis: {
			type: 'category',
			boundaryGap: true,
			data: option.x_data,
			axisTick: {
				alignWithLabel: true
			}
		},
		yAxis: {
			type: 'value',
			minInterval: 1,
			splitLine: {
				show: true,
				lineStyle: {
					color: ['#e9edef'],
					width: 1,
					type: 'solid'
				}
			}
		},
		series: [{
			name: option.legend[0],
			type: 'bar',
			smooth: true,
			stack: '数量',
			data: option.bar_data,
			barWidth: '40',
			itemStyle: {
				color: '#67C23A'
			}
		}]
	})

	setTimeout(() => {
		window.onresize = function () {
			echar.resize();
		}
	}, 200)
}

// echar 饼图
export function setEchartPie(option) {
	let echar = echarts.init(document.getElementById(option.el_id));
	echar.clear();
	echar.setOption({
		tooltip: {
			trigger: 'item'
		},
		series: [{
			type: 'pie',
			radius: '50%',
			data: option.data,
			emphasis: {
				itemStyle: {
					shadowBlur: 10,
					shadowOffsetX: 0,
					shadowColor: 'rgba(0, 0, 0, 0.5)'
				}
			}
		}]
	})

	setTimeout(() => {
		window.onresize = function () {
			echar.resize();
		}
	}, 200)
}

// 加法精度
export function addOpera(arg1, arg2) {
	let r1, r2, m, n
	try {
		r1 = arg1.toString().split('.')[1].length;
	} catch (e) {
		r1 = 0;
	}
	try {
		r2 = arg2.toString().split('.')[1].length;
	} catch (e) {
		r2 = 0;
	}
	m = Math.pow(10, Math.max(r1, r2));
	n = r1 >= r2 ? r1 : r2
	return +((arg1 * m + arg2 * m) / m).toFixed(n)
}

// 减法
export function subtr(arg1, arg2) {
	var r1, r2, m, n;
	try {
		r1 = arg1.toString().split(".")[1].length;
	} catch (e) {
		r1 = 0;
	}
	try {
		r2 = arg2.toString().split(".")[1].length;
	} catch (e) {
		r2 = 0;
	}
	m = Math.pow(10, Math.max(r1, r2));
	//last modify by deeka
	//动态控制精度长度
	n = (r1 >= r2) ? r1 : r2;
	return ((arg1 * m - arg2 * m) / m).toFixed(n);
}

// 乘法精度
export function mulOpera(a, b) {
	var c = 0,
		d = a.toString(),
		e = b.toString();
	try {
		c += d.split(".")[1].length;
	} catch (e) {
		// continue regardless of error
	}
	try {
		c += e.split(".")[1].length;
	} catch (e) {
		// continue regardless of error
	}
	return (
		(Number(d.replace(".", "")) * Number(e.replace(".", ""))) /
		Math.pow(10, c)
	);
}

// 除法精度
export function divOpera(a, b) {
	var c,
		d,
		e = 0,
		f = 0;
	try {
		e = a.toString().split(".")[1].length;
	} catch (e) {
		// continue regardless of error
	}
	try {
		f = b.toString().split(".")[1].length;
	} catch (e) {
		// continue regardless of error
	}
	return (
		(c = Number(a.toString().replace(".", ""))),
		(d = Number(b.toString().replace(".", ""))),
		// this.mulOpera(c / d, Math.pow(10, f - e))
		mulOpera(c / d, Math.pow(10, f - e))
	);
}

// 圆柱图
export function setEchartCircular(option) {
	let echar = echarts.init(document.getElementById(option.el_id))
	echar.setOption({
		series: [{
			type: 'pie',
			data: [{
					value: 335,
					name: '直接访问'
				},
				{
					value: 234,
					name: '联盟广告'
				},
				{
					value: 1548,
					name: '搜索引擎'
				}
			]
		}]
	})

	setTimeout(() => {
		window.onresize = function () {
			echar.resize();
		}
	}, 200)
}

// 企发易折线图
export function EchartLineEnt(option) {
	let echar = echarts.init(document.getElementById(option.el_id))
	echar.setOption({
		xAxis: {
			type: 'category',
			boundaryGap: false,
			data: option.option.x_data,
			axisLine: {
				show: false
			},
			axisTick: {
				show: false
			},
			axisLabel: {
				margin: 6,
				formatter: function (value) {
					return charAdjust(value, 4)
				},
			}
		},
		tooltip: {
			trigger: 'axis'
		},
		yAxis: {
			type: 'value',
			axisLine: {
				show: false
			},
			axisTick: {
				show: false
			},
			splitLine: {
				lineStyle: {
					type: 'dashed'
				}
			},
		},
		series: [{
			data: option.option.y_data,
			type: 'line',
			itemStyle: {
				normal: {
					label: {
						show: true
					},
					color: '#5873FE'
				},
			},
			areaStyle: {
				normal: {
					color: new echarts.graphic.LinearGradient(
						0, 0, 0, 1,
						[

							{
								offset: 0,
								color: "#EFF2FF"
							},

							{
								offset: 1,
								color: "#C9D2FF"
							},


						]
					)
				}
			},

		}],
		grid: {
			left: 70,
			width: 1860,
			height: 500,
		},
		lineStyle: {
			color: '#5873FE'
		}
	})

	setTimeout(() => {
		window.onresize = function () {
			echar.resize();
		}
	}, 200)
}

function charAdjust(charVal, num) {
	var newParamsName = ""; // 最终拼接成的字符串
	var paramsNameNumber = charVal.length; // 实际标签的个数
	var provideNumber = num; // 每行能显示的字的个数
	var rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 换行的话，需要显示几行，向上取整
	/**
	 * 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
	 */
	// 条件等同于rowNumber>1
	if (paramsNameNumber > provideNumber) {
		/** 循环每一行,p表示行 */
		for (var p = 0; p < rowNumber; p++) {
			var tempStr = ""; // 表示每一次截取的字符串
			var start = p * provideNumber; // 开始截取的位置
			var end = start + provideNumber; // 结束截取的位置
			// 此处特殊处理最后一行的索引值
			if (p == rowNumber - 1) {
				// 最后一次不换行
				tempStr = charVal.substring(start, paramsNameNumber);
			} else {
				// 每一次拼接字符串并换行
				tempStr = charVal.substring(start, end) + "\n";
			}
			newParamsName += tempStr; // 最终拼成的字符串
		}

	} else {
		// 将旧标签的值赋给新标签
		newParamsName = charVal;
	}
	//将最终的字符串返回
	return newParamsName
}

// 企发易圆饼
export function RoundcakeEnt(option) {

	console.log(option, '---option')

	let echar = echarts.init(document.getElementById(option.el_id))

	echar.setOption({
		title: {
			text: '7大投诉事项分布图',
			left: 260,
			bottom: 0
		},
		tooltip: {
			trigger: 'item'
		},
		legend: {
			top: 40,
			left: 700,
			icon: "circle",
			orient: 'vertical',
			itemGap: 55,
			textStyle: {
				color: '#666666',
				fontSize: 14,
				width: 300,
				padding: [0, 100, 0, 13],
			},
			formatter: (name) => {
				let index = option.option.findIndex(item => {
					return name == item.name
				})
				// if(name.length>6){
				// 	name=`${name.slice(0,10)}...`
				// }

				let text = name + '：' + option.option[index].value

				return charAdjust(text, 65)
				// return text
			},
		},
		grid: {
			width: 120,
			height: 60
		},
		series: [{
			type: 'pie',
			radius: ['40%', '80%'],
			center: ['20%', '50%'],
			avoidLabelOverlap: false,
			label: {
				show: false,
				position: 'center',
			},
			itemStyle: {
				normal: {
					label: {
						show: true,
						formatter(value) {
							return (((value.data.value / option.total_count) * 100).toFixed(2)) + '%';
						},
					},
					labelLine: {
						show: true
					}
				}
			},

			emphasis: {
				label: {
					show: false,
					fontSize: '20',
					fontWeight: 'bold'
				}
			},
			labelLine: {
				show: false
			},
			color: ['#27C2FF', '#6C73FF', '#00BABA', '#47D468', '#C7E316', '#F5A41F', '#59A7FF'],
			data: option.option,
		}],
	})

	setTimeout(() => {
		window.onresize = function () {
			echar.resize();
		}
	}, 200)
}

// 企发易地图
export function MapEnt(option) {
	let echar = echarts.init(document.getElementById(option.el_id))

	let customSettings = [{
			start: 1,
			end: 1,
			label: '北京',
			color: '#cfc5de'
		},
		{
			start: 2,
			end: 2,
			label: '天津',
			color: '#f1ebd1'
		},
		{
			start: 3,
			end: 3,
			label: '上海',
			color: '#feffdb'
		},
		{
			start: 4,
			end: 4,
			label: '重庆',
			color: '#e0cee4'
		},
		{
			start: 5,
			end: 5,
			label: '河北',
			color: '#fde8cd'
		},
		{
			start: 6,
			end: 6,
			label: '河南',
			color: '#e4f1d7'
		},
		{
			start: 7,
			end: 7,
			label: '云南',
			color: '#fffed7'
		},
		{
			start: 8,
			end: 8,
			label: '辽宁',
			color: '#e4f1d7'
		},
		{
			start: 9,
			end: 9,
			label: '黑龙江',
			color: '#e4f1d7'
		},
		{
			start: 10,
			end: 10,
			label: '湖南',
			color: '#fffed7'
		},
		{
			start: 11,
			end: 11,
			label: '安徽',
			color: '#fffed8'
		},
		{
			start: 12,
			end: 12,
			label: '山东',
			color: '#dccee7'
		},
		{
			start: 13,
			end: 13,
			label: '新疆',
			color: '#fffed7'
		},
		{
			start: 14,
			end: 14,
			label: '江苏',
			color: '#fce8cd'
		},
		{
			start: 15,
			end: 15,
			label: '浙江',
			color: '#ddceeb'
		},
		{
			start: 16,
			end: 16,
			label: '江西',
			color: '#e4f1d3'
		},
		{
			start: 17,
			end: 17,
			label: '湖北',
			color: '#fde8cd'
		},
		{
			start: 18,
			end: 18,
			label: '广西',
			color: '#fde8cd'
		},
		{
			start: 19,
			end: 19,
			label: '甘肃',
			color: '#fde8cd'
		},
		{
			start: 20,
			end: 20,
			label: '山西',
			color: '#fffdd6'
		},
		{
			start: 21,
			end: 21,
			label: '内蒙古',
			color: '#ddcfe6'
		},
		{
			start: 22,
			end: 22,
			label: '陕西',
			color: '#fad8e9'
		},
		{
			start: 23,
			end: 23,
			label: '吉林',
			color: '#fce8cd'
		},
		{
			start: 24,
			end: 24,
			label: '福建',
			color: '#fad8e8'
		},
		{
			start: 25,
			end: 25,
			label: '贵州',
			color: '#fad8e8'
		},
		{
			start: 26,
			end: 26,
			label: '广东',
			color: '#ddcfe8'
		},
		{
			start: 27,
			end: 27,
			label: '青海',
			color: '#fad8e9'
		},
		{
			start: 28,
			end: 28,
			label: '西藏',
			color: '#ddcfe6'
		},
		{
			start: 29,
			end: 29,
			label: '四川',
			color: '#e4f1d5'
		},
		{
			start: 30,
			end: 30,
			label: '宁夏',
			color: '#fefcd5'
		},
		{
			start: 31,
			end: 31,
			label: '海南',
			color: '#fad8e9'
		},
		{
			start: 32,
			end: 32,
			label: '台湾',
			color: '#fce8cd'
		},
		{
			start: 33,
			end: 33,
			label: '香港',
			color: '#dc9bbb'
		},
		{
			start: 34,
			end: 34,
			label: '澳门',
			color: '#e0f7cc'
		}
	]

	let arr = []

	for (let item of customSettings) {
		arr.push({
			name: item.label,
			itemStyle: {
				color: item.color,
				areaColor: item.color
			}
		})
	}


	var dataValue = dealWithData(option.option);
	// var data1 = dataValue.splice(0, 6);
	echar.setOption({
		tooltip: {
			show: false
		},
		geo: {
			map: "china",
			roam: false, // 一定要关闭拖拽
			zoom: 1.23,
			center: [105, 36], // 调整地图位置
			regions: arr,
			label: {
				normal: {
					show: false, //关闭省份名展示
					fontSize: "10",
					color: "rgba(0,0,0,0.7)"
				},
				emphasis: {
					show: false
				}
			},
			itemStyle: {
				normal: {
					// areaColor: "#0d0059",
					// borderColor: "#389dff",
					// borderWidth: 1, //设置外层边框
					// shadowBlur: 5,
					// shadowOffsetY: 8,
					// shadowOffsetX: 0,
					// shadowColor: "#01012a"
				},
				emphasis: {
					areaColor: "#5873FE",
					shadowOffsetX: 0,
					shadowOffsetY: 0,
					shadowBlur: 5,
					borderWidth: 0,
					shadowColor: "rgba(0, 0, 0, 0.5)"
				}
			},
			silent: true,
			left: 100,
			top: 100,
			layoutSize: 200,
		},
		series: [

			{
				name: "",
				type: "scatter",
				coordinateSystem: "geo",
				data: dataValue,
				//   symbolSize: function(val) {
				//     return val[2] / 10;
				//   },
				symbol: "circle",
				symbolSize: 8,
				hoverSymbolSize: 10,
				tooltip: {
					formatter(value) {
						return value.data.name + "<br/>" + "用户数：" + value.data.num;
					},
					show: true
				},
				encode: {
					value: 2
				},
				label: {
					formatter: "{b}",
					position: "right",
					show: false
				},
				itemStyle: {
					color: "#5873FE"
				},
				emphasis: {
					label: {
						show: false
					}
				}
			},

		]
	})

	let options = {
		tooltip: {
			show: false
		},
		geo: {
			map: "china",
			roam: false, // 一定要关闭拖拽
			zoom: 1.23,
			center: [105, 36], // 调整地图位置
			regions: arr,
			label: {
				normal: {
					show: false, //关闭省份名展示
					fontSize: "10",
					color: "rgba(0,0,0,0.7)"
				},
				emphasis: {
					show: false
				}
			},
			itemStyle: {
				normal: {
					// areaColor: "#0d0059",
					// borderColor: "#389dff",
					// borderWidth: 1, //设置外层边框
					// shadowBlur: 5,
					// shadowOffsetY: 8,
					// shadowOffsetX: 0,
					// shadowColor: "#01012a"
				},
				emphasis: {
					areaColor: "#5873FE",
					shadowOffsetX: 0,
					shadowOffsetY: 0,
					shadowBlur: 5,
					borderWidth: 0,
					shadowColor: "rgba(0, 0, 0, 0.5)"
				}
			}
		},

	};
	// echar.setOption(options);


	setTimeout(() => {
		window.onresize = function () {
			echar.resize();
		}
	}, 200)
}

function dealWithData(option) {

	console.log(option, '---option')

	var data = [];

	for (let item of option) {
		data.push({
			name: item.district,
			value: [item.lng - 0, item.lat - 0],
			num: item.user_count
		});
	}

	return data;
}

/**
 * 随机生成字符串
 * @param len 指定生成字符串长度
 */
const _charStr = 'abacdefghjklmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ0123456789';

/**
 * 随机生成索引
 * @param min 最小值
 * @param max 最大值
 * @param i 当前获取位置
 */
function RandomIndex(min, max, i) {
	let index = Math.floor(Math.random() * (max - min + 1) + min),
		numStart = _charStr.length - 10;
	//如果字符串第一位是数字，则递归重新获取
	if (i == 0 && index >= numStart) {
		index = RandomIndex(min, max, i);
	}
	//返回最终索引值
	return index;
}

export function getRandomString(len) {
	let min = 0,
		max = _charStr.length - 1,
		_str = '';
	//判断是否指定长度，否则默认长度为15
	len = len || 15;
	//循环生成字符串
	for (var i = 0, index; i < len; i++) {
		index = RandomIndex(min, max, i);
		_str += _charStr[index];
	}
	return _str;
}
/**
 * @param {Object} type 那种类型的页面高度
 */
export function returnH(type = 0, className) {
	return new Promise(function (resolve, reject) {
		let newH = 0;
		//做一些异步操作
		switch (type) {
			case 0:
				newH = document.documentElement.clientHeight - (10 + 78 + 50);
				resolve(newH);
				break;
			case 1:
				setTimeout(() => {
					let searchH = document.querySelector(className).offsetHeight;
					if (searchH > 72) {
						newH = document.documentElement.clientHeight - (192 + searchH);
						resolve(newH);
					} else {
						newH = document.documentElement.clientHeight - 264;
						resolve(newH);
					}
				}, 100)
				break;
			case 2:
				newH = document.documentElement.clientHeight - (140 + 50);
				resolve(newH);
				break;
		}
	});
}

/**
 * 阿拉伯数字转换大写
 */
export function numberParseChina(money) {
	//汉字的数字
	var cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
	//基本单位
	var cnIntRadice = ['', '拾', '佰', '仟'];
	//对应整数部分扩展单位
	var cnIntUnits = ['', '万', '亿', '兆'];
	//对应小数部分单位
	var cnDecUnits = ['角', '分', '毫', '厘'];
	//整数金额时后面跟的字符
	var cnInteger = '整';
	//整型完以后的单位
	var cnIntLast = '圆';
	//最大处理的数字
	var maxNum = 999999999999999.9999;
	//金额整数部分
	var integerNum;
	//金额小数部分
	var decimalNum;
	//输出的中文金额字符串
	var chineseStr = '';
	//分离金额后用的数组，预定义
	var parts;
	if (money == '') {
		return '';
	}
	money = parseFloat(money);
	if (money >= maxNum) {
		//超出最大处理数字
		return '';
	}
	if (money == 0) {
		chineseStr = cnNums[0] + cnIntLast + cnInteger;
		return chineseStr;
	}
	//转换为字符串
	money = money.toString();
	if (money.indexOf('.') == -1) {
		integerNum = money;
		decimalNum = '';
	} else {
		parts = money.split('.');
		integerNum = parts[0];
		decimalNum = parts[1].substr(0, 4);
	}
	//获取整型部分转换
	if (parseInt(integerNum, 10) > 0) {
		var zeroCount = 0;
		var IntLen = integerNum.length;
		for (var i = 0; i < IntLen; i++) {
			var n = integerNum.substr(i, 1);
			var p = IntLen - i - 1;
			var q = p / 4;
			var m = p % 4;
			if (n == '0') {
				zeroCount++;
			} else {
				if (zeroCount > 0) {
					chineseStr += cnNums[0];
				}
				//归零
				zeroCount = 0;
				chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
			}
			if (m == 0 && zeroCount < 4) {
				chineseStr += cnIntUnits[q];
			}
		}
		chineseStr += cnIntLast;
	}
	//小数部分
	if (decimalNum != '') {
		var decLen = decimalNum.length;
		for (var i = 0; i < decLen; i++) {
			var n = decimalNum.substr(i, 1);
			if (n != '0') {
				chineseStr += cnNums[Number(n)] + cnDecUnits[i];
			}
		}
	}
	if (chineseStr == '') {
		chineseStr += cnNums[0] + cnIntLast + cnInteger;
	} else if (decimalNum == '') {
		chineseStr += cnInteger;
	}
	return chineseStr;
}