<template>
  <div class="leftmenu">
    <div class="morelist">
      <div class="text" v-for="(nav, i) in list" :key="i" @click="navpath(nav, i)">
        <div class="titles" :class="{ navactive: nav.path == $route.path, towmenu: !nav.path }">
          <div class="left-title">
            <svg-icon v-if="nav.meta.icon" :class="{'hi-svg': nav.path == $route.path}" :icon-class="nav.meta.icon"
              style="margin-right: 4px;font-size: 18px;color: #999;position: relative;top: 3px;"></svg-icon>
            <span>{{ nav.meta.title }}</span>
          </div>
          <span class="icons" :class="{ transition: childindex == i || nav.isShow }"
            v-if="nav.children && nav.children.length > 0">
            <i class="el-icon-arrow-right"></i>
          </span>
          <div class="activeBack">{{ nav.meta.title }}</div>
        </div>

        <el-collapse-transition>
          <div class="flex-list" v-show="childindex == i || nav.isShow">
            <div v-for="(children, children_i) in nav.children" :key="children_i">
              <div class="childtext" :class="{ navactive: children.path == $route.path }"
                v-if="children.meta.layout == 1" :key="children_i" @click.stop="threepath(children)">
                <span>{{ children.meta.title }}</span>
                <div class="activeBack">{{ children.meta.title }}</div>
              </div>
            </div>
          </div>
        </el-collapse-transition>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        meun: [],
        list: [],
        childindex: 0,
        userinfo: "",
        loading: true,
        isShow: true,
      };
    },
    created() {
      let routes = JSON.parse(sessionStorage.getItem("routestext"));
      routes.forEach((item) => {
        if (item.meta.layout == 1) {
          this.meun.push(item);
        }
      });
      this.children_route();
    },
    mounted() {
      this.getInfo();
    },
    watch: {
      $route: function (newflag, oldflag) {
        this.children_route();
      },
    },
    methods: {
      //获取信息
      getInfo() {
        let info = JSON.parse(sessionStorage.getItem("userinfo"));
        if (info && info.roles && info.roles.length > 0) {
          let guard_name = "";
          if (info.roles[0].id == 59) {
            guard_name = "api";
          }
          if (info.roles[0].id == 22) {
            guard_name = "store_admin_user";
          }
          if (!guard_name) {
            return;
          }
          this.loading = false;
          this.axios
            .get("/api/me", {
              params: {
                guard_name: guard_name,
              },
            })
            .then((res) => {
              this.loading = true;
              if (res.code == 200) {
                this.userinfo = JSON.parse(JSON.stringify(res.data));
                sessionStorage.setItem("userinfo", JSON.stringify(res.data));
                if (res.data.unitid == 2) {
                  sessionStorage.setItem("role_id", res.data.roles[0].id);
                }
              }
            });
        }
      },
      calclayout(arr) {
        console.log('%c [ arr ]-102', 'font-size:13px; background:#83f2a2; color:#c7ffe6;', arr)
        let count = 0;
        arr.forEach((item) => {
          if (item.layout == 1) {
            count++;
          }
        });
        if (count > 1) {
          return true;
        } else {
          return false;
        }
      },
      //监控子路由
      children_route() {
        let that = this;
        let routes = JSON.parse(sessionStorage.getItem("routestext"));
        if (this.$route.matched.length > 1) {
          routes.forEach((item) => {
            if (item.meta.title == that.$route.matched[0].meta.title) {
              that.list = [];
              if (item.children && item.children.length) item.children.forEach((child) => {
                if (child.meta.layout == 1) {
                  that.list.push(child);
                }
              });
            }
          });
          if (that.list.length > 0) {
            this.$store.commit("changeasideWidth", 50);
          } else {
            this.$store.commit("changeasideWidth", 0);
          }
        } else {
          this.list = JSON.parse(sessionStorage.getItem("routestext"));
          routes.forEach((item) => {
            if (item.meta.title == that.$route.matched[0].meta.title) {
              that.list = [];
              if (item.children && item.children.length) item.children.forEach((child) => {
                if (child.meta.layout == 1) {
                  that.list.push(child);
                }
              });
            }
          });
          // this.list = [];
          // let routes = JSON.parse(sessionStorage.getItem("routestext"));
          // routes.forEach((item) => {
          //   if (item.meta.layout == 1) {
          //     item.isShow = true;
          //     this.list.push(item);
          //   }
          // });
          // console.log(this.list, "this.list");
          this.$store.commit("changeasideWidth", 0);
        }
      },
      //跳转
      meunlink(item, index) {
        if (item.path) {
          if (item.path != this.$route.path) {
            let userinfo = JSON.parse(sessionStorage.getItem("userinfo")) || "";
            let unitid = userinfo.unitid || "";

            if (item.path == "/applist") {
              let routes = JSON.parse(sessionStorage.getItem("routes"));
              routes.some((item, index) => {
                if (item.path == "/applist" && item.component == "Layout") {
                  item._child = [];
                  item.component = "applist/applist.vue";
                  resetRouter();
                  router.addRoutes(changeroute(routes));
                  sessionStorage.setItem("routes", JSON.stringify(routes));
                }
              });
            }

            if (item.path == "/voteapps") {
              let routes = JSON.parse(sessionStorage.getItem("routes"));
              routes.some((item, index) => {
                if (item.path == "/voteapps" && item.component == "Layout") {
                  item._child = [];
                  item.component = "apps/vote/apps/apps.vue";
                  resetRouter();
                  router.addRoutes(changeroute(routes));
                  sessionStorage.setItem("routes", JSON.stringify(routes));
                }
              });
            }

            if (item.path == "/channel" && unitid == 3) {
              let routes = JSON.parse(sessionStorage.getItem("routes"));
              routes.some((item, index) => {
                if (item.path == "/channel" && item.component == "Layout") {
                  item._child = [];
                  item.component = "apps/vote/channel/channel.vue";
                  resetRouter();
                  router.addRoutes(changeroute(routes));
                  sessionStorage.setItem("routes", JSON.stringify(routes));
                }
              });
            }

            //渠道一级菜单 清空动态添加
            if (item.path == "/channel" && unitid != 3) {
              let routes = JSON.parse(sessionStorage.getItem("routes"));
              routes.some((item, index) => {
                if (item.path == "/channel" && item.component == "Layout") {
                  item._child = [];
                  item.component = "apps/channel/channel.vue";
                  resetRouter();
                  router.addRoutes(changeroute(routes));
                  sessionStorage.setItem("routes", JSON.stringify(routes));
                }
              });
            }

            //应用一级菜单 清空动态添加
            if (item.path == "/application") {
              let routes = JSON.parse(sessionStorage.getItem("routes"));
              routes.some((item, index) => {
                if (item.path == "/application" && item.component == "Layout") {
                  item.component = "apps/clean/apps/apps.vue";

                  // if(unitid==4){
                  // 	item.component = 'apps/shopping/apps/apps.vue'
                  // }

                  resetRouter();
                  router.addRoutes(changeroute(routes));
                  sessionStorage.setItem("routes", JSON.stringify(routes));
                }
              });
            }

            this.$router.push({
              path: item.path,
            });
            this.$store.commit("changeasideWidth", 0);
          }
        } else {
          if (item.children[0].path != this.$route.path) {
            for (let i = 0; i < item.children.length; i++) {
              let child = item.children[i];
              if (child.meta.layout == 1) {
                if (child.children) {
                  this.childindex = 0;
                  if (child.children[0].path == this.$route.path) {
                    return;
                  }

                  if (child.children[0].meta.props) {
                    this.$router.push({
                      path: child.children[0].path,
                      query: {
                        props: child.children[0].meta.props,
                      },
                    });
                    return;
                  } else {
                    this.$router.push({
                      path: child.children[0].path,
                    });
                    return;
                  }
                } else {
                  this.childindex = -1;
                  if (child.meta.props) {
                    this.$router.push({
                      path: child.path,
                      query: {
                        props: child.meta.props,
                      },
                    });
                  } else {
                    this.$router.push({
                      path: child.path,
                    });
                  }
                  this.$store.commit("changeasideWidth", 50);
                  return;
                }
              }
            }
            this.$store.commit("changeasideWidth", 0);
          }
        }
      },
      //二级菜单跳转
      navpath(item, index) {
        this.$set(this.list[index], "isShow", false);
        if (item.children) {
          if (this.childindex == index) {
            this.childindex = -1;
            // return
          } else {
            this.childindex = index;
          }
        } else {
          this.childindex = -1;
          if (item.path && item.path != this.$route.path) {
            if (item.meta.props) {
              this.$router.push({
                path: item.path,
                query: {
                  props: item.meta.props,
                },
              });
            } else {
              this.$router.push({
                path: item.path,
              });
            }
          }
        }
      },
      //三级菜单跳转
      threepath(item) {
        if (item.path && item.path != this.$route.path) {
          if (item.meta.props) {
            this.$router.push({
              path: item.path,
              query: {
                props: item.meta.props,
              },
            });
          } else {
            this.$router.push({
              path: item.path,
            });
          }
        } else {
          let path = "";
          if (item.meta.level == 2) {
            if (item.children) {
              path = item.children[0].path;
            }
          }
          this.$router.push({
            path,
          });
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  ::v-deep .el-icon-arrow-right:before {
    content: "\e790";
    font-size: 12px;
  }

  .leftmenu {
    width: 160px;
    height: calc(100vh - 68px);
    // background: var(--nav,#1e222b);
    position: absolute;
    top: 68px;
    background: #fff;
  }

  .morelist {
    width: 100%;
    height: 100%;
    display: inline-block;
    vertical-align: top;
    // padding-top: 60px;
    overflow-y: auto;
    z-index: 999;
    cursor: pointer;
    background: transparent;

    .text {
      color: #666;
      font-size: 15px;
      font-weight: normal;
      line-height: 40px;
      height: auto;
      overflow: hidden;
      user-select: none;
    }

    .titles:hover {
      color: #2d8cf0;
    }

    .titles {
      padding: 0 20px;
      display: flex;
      align-items: center;
      // color: var(--navFontDefault,#d8d7d7);
      color: #333;
      position: relative;

      .icons {
        margin-left: 4px;
        font-size: 14px;
        // color: var(--navFontDefault,#d8d7d7);
        color: #666;
        font-weight: 600;
        transition: all 0.3s;
        position: absolute;
        right: 15px;
      }

      .transition {
        transform: rotate(180deg);
      }
    }

    .flex-list {
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      padding: 0 16px;
      box-sizing: border-box;
    }

    .childtext {
      width: 86px;
      font-size: 14px;
      color: #8a8c99;
      background: transparent;
      display: flex;
      justify-content: space-between;
      margin-left: 27px;

      &.navactive {
        .activeBack {
          color: #2d8cf0 !important;
          padding: 0 !important;
          justify-content: flex-start !important;
        }
      }
    }

    .childtext:hover {
      color: #2d8cf0;
    }

    .towmenu {
      // font-weight: bold;
      line-height: 40px;

      div {
        text-align: left !important;
        font-weight: normal;
        color: #666;
        // color: var(--navFontDefault,#d8d7d7);
      }
    }

    .towmenu:hover {
      color: #2d8cf0 !important;
    }

    .activeBack {
      display: none;
    }

    .navactive {
      height: 40px;
      line-height: 40px;
      position: relative;
      font-weight: bold;

      span {
        display: none;
      }

      .activeBack {
        // padding: 0 15px;
        display: block;
        line-height: 40px;
        border-radius: 8px;
        color: #2d8cf0 !important;
        position: relative;
        left: 0;
        // background: rgba(45, 140, 240, 0.1) !important;
        // position: absolute;
        // top: 2px;
        // left: 5px;
        // right: 5px;
        // bottom: 2px;
        // z-index: 0;
      }
    }
  }

  .hi-svg {
    color: #479cf7 !important;
  }
</style>