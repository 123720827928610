<template>
  <div class="informationbox"
    :style="{'background':plugins_data.style.background,'margin':plugins_data.style.padding_top/2+'px '+plugins_data.style.padding_left/2+'px '+plugins_data.style.padding_bottom/2+'px','borderRadius':plugins_data.style.border_radius_top/2+'px '+plugins_data.style.border_radius_top/2+'px '+plugins_data.style.border_radius_bottom/2+'px '+plugins_data.style.border_radius_bottom/2+'px',}">
    <div style="display:flex;align-items:center;padding: 5px 10px;">
      <div class="iconbox">
        <template v-if="plugins_data.data.positionIcon">
          <el-image style="width: 100%;height: 100%;" :src="plugins_data.data.positionIcon" fit="cover"></el-image>
        </template>
        <template v-else>
          <svg-icon icon-class="position" :style="{'color':plugins_data.style.color,'fontSize':'30px'}"></svg-icon>
        </template>
      </div>
      <div class="information" :style="{'color':plugins_data.style.color}">
        <div class="item">
          <div class="text">
            营业时间：{{ plugins_data.data.time }},
            联系电话：{{ plugins_data.data.phone }}
          </div>
        </div>
        <div class="item">
          <div class="text">
            商家地址：{{ (plugins_data.data.area.length?plugins_data.data.area.join(''):'')+plugins_data.data.adress }}
          </div>
        </div>
      </div>
      <div class="iconbox">
        <template v-if="plugins_data.data.telIcon">
          <el-image style="width: 100%;height: 100%;" :src="plugins_data.data.telIcon" fit="cover"></el-image>
        </template>
        <template v-else>
          <svg-icon icon-class="call" :style="{'color':plugins_data.style.color,'fontSize':'30px'}"></svg-icon>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'informationView',
    data() {
      return {
        list: [],
      };
    },
    props: {
      plugins_data: {
        type: Object
      }
    },
  };
</script>

<style scoped lang="less">
  .informationbox {
    overflow: hidden;

    .iconbox {
      flex: 0 0 30px;
      width: 30px;
      height: 30px;
      margin-right: 10px;

      &:last-child {
        margin-right: 0px;
        margin-left: 10px;
      }
    }

    .information {
      flex: 1;

      .item {
        width: 100%;
        height: 22px;

        .text {
          font-size: 12px;
          line-height: 22px;
          overflow: hidden;
        }
      }
    }
  }
</style>