<template>
  <div>
    <div class="list">
      <div class="menu-list">
        <div class="logo">
          <div class="logo-left">
            <el-image :src="logo" :class="{error:error}" fit="fill" @error="handleImg">
            </el-image>
            <div class="name">{{ time_text }}{{ userinfo.shop_name || userinfo.user_name }}</div>
          </div>
          <img class="show-edit" src="@/assets/icon/edit.png" @click="toEdit" style="width: 13px; height: 13px" />
        </div>
        <div class="menu-item" v-for="(item, index) in meun" :key="index" @click="meunlink(item)">
          <div class="activeBox">
            <a href="javascript:void(0);" class="menu-name"
              :class="{ active: item.meta.router_name == $route.meta.router_name }">
              <!-- <svg-icon
                v-show="item.meta.title == '应用'"
                :icon-class="item.meta.icon"
                style="
                  margin-right: 3px;
                  font-size: 16px;
                  position: relative;
                  top: 2px;
                  color: #7a8599;
                "
              ></svg-icon> -->
              {{ item.meta.title }}
            </a>
          </div>
        </div>
      </div>
      <div class="right-setting">
        <img class="icon-img" @click="tips" :src="item.icon" alt="" v-for="(item, index) in imgs" :key="index" />
        <el-dropdown trigger="click" class="menu headernava" @command="handleCommand">
          <div class="el-dropdown-link">
            <img src="@/assets/icon/more.png" alt="more" class="icon-img" />
          </div>
          <el-dropdown-menu slot="dropdown" class="drop-menu">
            <el-dropdown-item command="changeuser" class="item-font" v-if="userinfo && userinfo.guard_name == 'api'"><i
                class="el-icon-set-up el-icon--left"></i>修改账号</el-dropdown-item>
            <el-dropdown-item command="changepwd" class="item-font" v-if="userinfo && userinfo.guard_name == 'api'"><i
                class="el-icon-lock el-icon--left"></i>修改密码</el-dropdown-item>
            <el-dropdown-item command="loginout" class="item-font"><i
                class="el-icon-switch-button el-icon--left"></i>退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    resetRouter
  } from "@/router/index.js";
  import router from "@/router/index.js";
  import {
    changeroute
  } from "@/router/routerPermission.js";
  import {
    eltips
  } from "@/util/util.js";
  export default {
    data() {
      return {
        error: false,
        time_text: "上午好！",
        meun: [],
        list: [],
        childindex: 0,
        userinfo: {},
        projectinfo: {},
        loading: true,
        logo: require("@/assets/icon/application.png"),
        menuicon: require("@/assets/icon/icon-menu.png"),
        imgs: [{
            id: 0,
            icon: require("@/assets/icon/seach.png"),
            url: ""
          },
          {
            id: 1,
            icon: require("@/assets/icon/qsttion.png"),
            url: ""
          },
          {
            id: 2,
            icon: require("@/assets/icon/tips.png"),
            url: ""
          },
        ],
        ldata: {},
      };
    },
    created() {
      this.projectinfo = JSON.parse(sessionStorage.getItem("projectinfo"));
      let data = JSON.parse(sessionStorage.getItem("userinfo"));
      this.userinfo = data;
      this.logo = this.userinfo.admin_log || (this.projectinfo ? this.projectinfo.logo : this.logo)
      let routes = JSON.parse(sessionStorage.getItem("routestext"));
      routes.forEach((item) => {
        if (item.meta.layout == 1) {
          this.meun.push(item);
        }
      });
      this.children_route();
      this.gettime();
    },
    mounted() {
      // this.getInfo();
      // this.getSetting();
    },
    watch: {
      $route: function (newflag, oldflag) {
        this.children_route();
      },
    },
    methods: {
      //根据时间改变提示语
      gettime() {
        let time = new Date().getHours();
        if (time < 6) {
          this.time_text = "夜深了！";
        } else if (time < 12) {
          this.time_text = "早上好！";
        } else if (time < 14) {
          this.time_text = "中午好！";
        } else if (time < 18) {
          this.time_text = "下午好！";
        } else if (time < 23) {
          this.time_text = "晚上好！";
        } else {
          this.time_text = "夜深了！";
        }
      },
      //获取设置
      getSetting() {
        this.loading = true;
        this.axios.get("/api/retail/admin/settings/basic").then((res) => {
          this.loading = false;
          if (res.code == 200 && res.data) {
            this.ldata = res.data;
            console.log('%c [ this.ldata ]-140', 'font-size:13px; background:#8dd2d3; color:#d1ffff;', this.ldata)
          } else {
            eltips(res.msg, "error");
          }
        });
      },
      //tips
      tips() {
        this.$message("功能开发中，敬请期待！");
      },
      //跳转编辑
      toEdit() {
        this.$router.push({
          path: "/shopping/setting/shoppingsetting/basics",
        });
      },
      handleImg() {
        this.error = true
        this.logo = require("@/assets/icon/application.png")
      },
      //获取信息
      getInfo() {
        let info = JSON.parse(sessionStorage.getItem("userinfo"));
        if (info && info.roles && info.roles.length > 0) {
          let guard_name = "";
          if (info.roles[0].id == 59) {
            guard_name = "api";
          }
          if (info.roles[0].id == 22) {
            guard_name = "store_admin_user";
          }
          if (!guard_name) {
            return;
          }
          this.loading = false;
          this.axios
            .get("/api/me", {
              params: {
                guard_name: guard_name,
              },
            })
            .then((res) => {
              this.loading = true;
              if (res.code == 200) {
                this.userinfo = JSON.parse(JSON.stringify(res.data));
                sessionStorage.setItem("userinfo", JSON.stringify(res.data));
                if (res.data.unitid == 2) {
                  sessionStorage.setItem("role_id", res.data.roles[0].id);
                }
              }
            });
        }
      },
      calclayout(arr) {
        let count = 0;
        arr.forEach((item) => {
          if (item.layout == 1) {
            count++;
          }
        });
        if (count > 1) {
          return true;
        } else {
          return false;
        }
      },
      //监控子路由
      children_route() {
        let that = this;
        let routes = JSON.parse(sessionStorage.getItem("routestext"));
        if (this.$route.matched.length > 1) {
          routes.forEach((item) => {
            if (item.meta.title == that.$route.matched[0].meta.title) {
              that.list = [];
              if (item.children && item.children.length > 0) {
                item.children.forEach((child) => {
                  if (child.meta.layout == 1) {
                    that.list.push(child);
                  }
                });
              }
            }
          });
          if (that.list.length > 0) {
            this.$store.commit("changeasideWidth", 50);
          } else {
            this.$store.commit("changeasideWidth", 0);
          }
        } else {
          this.list = [];
          this.$store.commit("changeasideWidth", 0);
        }
      },
      //跳转
      meunlink(item) {
        console.log(item, "一级菜单跳转");
        if (item.path) {
          if (item.path != this.$route.path) {
            let userinfo = JSON.parse(sessionStorage.getItem("userinfo")) || "";
            let unitid = userinfo.unitid || "";

            if (item.path == "/applist") {
              let routes = JSON.parse(sessionStorage.getItem("routes"));
              routes.some((item, index) => {
                if (item.path == "/applist" && item.component == "Layout") {
                  item._child = [];
                  item.component = "applist/applist.vue";
                  resetRouter();
                  router.addRoutes(changeroute(routes));
                  sessionStorage.setItem("routes", JSON.stringify(routes));
                }
              });
            }

            if (item.path == "/voteapps") {
              let routes = JSON.parse(sessionStorage.getItem("routes"));
              routes.some((item, index) => {
                if (item.path == "/voteapps" && item.component == "Layout") {
                  item._child = [];
                  item.component = "apps/vote/apps/apps.vue";
                  resetRouter();
                  router.addRoutes(changeroute(routes));
                  sessionStorage.setItem("routes", JSON.stringify(routes));
                }
              });
            }

            if (item.path == "/channel" && unitid == 3) {
              let routes = JSON.parse(sessionStorage.getItem("routes"));
              routes.some((item, index) => {
                if (item.path == "/channel" && item.component == "Layout") {
                  item._child = [];
                  item.component = "apps/vote/channel/channel.vue";
                  resetRouter();
                  router.addRoutes(changeroute(routes));
                  sessionStorage.setItem("routes", JSON.stringify(routes));
                }
              });
            }
            if (item.path == "/channel" && unitid != 3) {
              let routes = JSON.parse(sessionStorage.getItem("routes"));
              routes.some((item, index) => {
                if (item.path == "/channel" && item.component == "Layout") {
                  item._child = [];
                  item.component = "apps/channel/channel.vue";
                  resetRouter();
                  router.addRoutes(changeroute(routes));
                  sessionStorage.setItem("routes", JSON.stringify(routes));
                }
              });
            }
            if (item.path == "/application") {
              let routes = JSON.parse(sessionStorage.getItem("routes"));
              routes.some((item, index) => {
                if (item.path == "/application" && item.component == "Layout") {
                  item.component = "apps/clean/apps/apps.vue";
                  resetRouter();
                  router.addRoutes(changeroute(routes));
                  sessionStorage.setItem("routes", JSON.stringify(routes));
                }
              });
            }

            this.$router.push({
              path: item.path,
            });
            this.$store.commit("changeasideWidth", 0);
          }
        } else {
          if (item.children[0].path != this.$route.path) {
            for (let i = 0; i < item.children.length; i++) {
              let child = item.children[i];
              if (child.meta.layout == 1) {
                if (child.children) {
                  this.childindex = 0;
                  if (child.children[0].path == this.$route.path) {
                    return;
                  }

                  if (child.children[0].meta.props) {
                    this.$router.push({
                      path: child.children[0].path,
                      query: {
                        props: child.children[0].meta.props,
                      },
                    });
                    return;
                  } else {
                    this.$router.push({
                      path: child.children[0].path,
                    });
                    return;
                  }
                } else {
                  this.childindex = -1;
                  if (child.meta.props) {
                    this.$router.push({
                      path: child.path,
                      query: {
                        props: child.meta.props,
                      },
                    });
                  } else {
                    this.$router.push({
                      path: child.path,
                    });
                  }
                  this.$store.commit("changeasideWidth", 50);
                  return;
                }
              }
            }
            this.$store.commit("changeasideWidth", 0);
          }
        }
      },
      //二级菜单跳转
      navpath(item, index) {
        if (item.children) {
          if (this.childindex == index) {
            this.childindex = -1;
            // return
          } else {
            this.childindex = index;
          }
          // if(item.children[0].path!=this.$route.path){
          // 	if(item.children[0].meta.props){
          // 		this.$router.push({path: item.children[0].path,query:{props:item.children[0].meta.props}})
          // 	}else{
          // 		this.$router.push({path: item.children[0].path})
          // 	}
          // }
        } else {
          this.childindex = -1;

          if (item.path && item.path != this.$route.path) {
            if (item.meta.props) {
              this.$router.push({
                path: item.path,
                query: {
                  props: item.meta.props,
                },
              });
            } else {
              this.$router.push({
                path: item.path,
              });
            }
          }
        }
      },
      //三级菜单跳转
      threepath(item) {
        if (item.path && item.path != this.$route.path) {
          if (item.meta.props) {
            this.$router.push({
              path: item.path,
              query: {
                props: item.meta.props,
              },
            });
          } else {
            this.$router.push({
              path: item.path,
            });
          }
        }
      },
      handleCommand(command) {
        let that = this;

        if (command == "accountInfo") {
          this.$router.push({
            path: "/account/accountInfo"
          });
        } else if (command == "changeuser") {
          this.$router.push({
            path: "/account/editaccount"
          });
        } else if (command == "changepwd") {
          this.$router.push({
            path: "/account/editpassword"
          });
        } else {
          if (sessionStorage.getItem("isApply")) {
            that.$router.replace({
              path: "/apply/login"
            });

            resetRouter();
            this.$store.commit("routestatus", 0);
            this.$store.commit("changeIsApply", 0);
            sessionStorage.removeItem("routestext");
            sessionStorage.removeItem("guardName");
            sessionStorage.removeItem("routes");
            sessionStorage.removeItem("userinfo");
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("isApply");
            return;
          }

          let guard_name = JSON.parse(
            sessionStorage.getItem("userinfo")
          ).guard_name;

          let data = {
            guard_name: guard_name,
          };

          this.axios.post("/api/logout", data).then((res) => {
            if (res.code == 200) {
              let userinfo = JSON.parse(sessionStorage.getItem("userinfo"));

              let type = userinfo.type;
              let guard_name = userinfo.guard_name;
              let guard_info = {
                type: type,
              };

              //判断登出到某个后台登录页面
              switch (guard_name) {
                default:
                  guard_info.guard_name = "api";
                  guard_info.name = userinfo.shop_name;
                  if (type == 0) {
                    that.$router.replace({
                      path: "/adminlogin"
                    });
                  } else {
                    that.$router.replace({
                      path: "/login"
                    });
                  }
                  break;
                case "periphery_admin_user": //联联周边游分后台 type： 1、站点 2、商家
                  guard_info.name = userinfo.user.shop_name;
                  guard_info.guard_name = "periphery_admin_user";
                  guard_info.shop_id = userinfo.user.shop_id;
                  that.$router.replace({
                    path: `/userlogin?name=${userinfo.user.shop_name}&type=${type}&shop_id=${userinfo.shop_id}&guard_name=periphery_admin_user`,
                  });
                  break;
                case "hous_keep_admin_user": //智慧家政分后台 type： 1、代理 2、商家
                  guard_info.name = userinfo.api_user.shop_name;
                  guard_info.guard_name = "hous_keep_admin_user";
                  guard_info.shop_id = userinfo.shop_id;
                  that.$router.replace({
                    path: `/cleanlogin?name=${userinfo.api_user.shop_name}&type=${type}&shop_id=${userinfo.shop_id}&guard_name=hous_keep_admin_user`,
                  });
                  break;
                case "store_admin_user": //商城商家后台
                  guard_info.name = userinfo.api_user.shop_name;
                  guard_info.guard_name = "store_admin_user";
                  guard_info.shop_id = userinfo.shop_id;
                  //是否简洁模式
                  let clear = sessionStorage.getItem("clear");
                  that.$router.replace({
                    path: `/shoplogin?name=${
                    userinfo.api_user.shop_name
                  }&type=${type}&shop_id=${
                    userinfo.shop_id
                  }&guard_name=store_admin_user${clear ? "&clear=1" : ""}`,
                  });
                  break;
                case "retail_admin_user": //新零售分后台
                  guard_info.name = userinfo.api_user.shop_name;
                  guard_info.guard_name = "retail_admin_user";
                  guard_info.shop_id = userinfo.shop_id;
                  that.$router.replace({
                    path: `/retaillogin?name=${userinfo.api_user.shop_name}&shop_id=${userinfo.shop_id}&guard_name=retail_admin_user`,
                  });
                  break;
                case "assessment_admin_user": //世大云心分后台
                  guard_info.name = that.shopName;
                  guard_info.guard_name = "assessment_admin_user";
                  guard_info.shop_id = userinfo.shop_id;
                  that.$router.replace({
                    path: `/psychologicalLogin?name=${that.shopName}&shop_id=${userinfo.shop_id}&guard_name=assessment_admin_user`,
                  });
                  break;
                case "gallery_admin_user": //图库分后台
                  guard_info.name = userinfo.api_user.shop_name;
                  guard_info.guard_name = "gallery_admin_user";
                  guard_info.shop_id = userinfo.shop_id;
                  that.$router.replace({
                    path: `/gallerylogin?name=${userinfo.api_user.shop_name}&shop_id=${userinfo.shop_id}&guard_name=gallery_admin_user`,
                  });
                  break;
                case "car_market": //二手车市场后台
                  guard_info.name = that.shopName;
                  guard_info.guard_name = "car_market";
                  guard_info.shop_id = userinfo.shop_id;
                  that.$router.replace({
                    path: `/carlogin?name=${that.shopName}&shop_id=${userinfo.shop_id}&guard_name=car_market`,
                  });
                  break;
                case "car_shop": //二手车车行分后台
                  guard_info.name = that.shopName;
                  guard_info.guard_name = "car_shop";
                  guard_info.shop_id = userinfo.shop_id;
                  that.$router.replace({
                    path: `/carRowLogin?name=${that.shopName}&shop_id=${userinfo.shop_id}&guard_name=car_shop`,
                  });
                  break;
              }

              resetRouter();
              this.$store.commit("routestatus", 0);
              sessionStorage.setItem("guard_info", JSON.stringify(guard_info));
              sessionStorage.removeItem("routestext");
              sessionStorage.removeItem("routes");
              sessionStorage.removeItem("userinfo");
              sessionStorage.removeItem("token");
              sessionStorage.removeItem("pluginroute");
              sessionStorage.removeItem("pluginid");
              sessionStorage.removeItem("isApply");
            } else {
              this.$message(res.msg);
            }
          });
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .list {
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0 12px;
    box-sizing: border-box;

    .menu-item {
      min-width: 64px;
      height: 100%;
      display: flex;
      align-items: center;
      color: #b6babf;
      cursor: pointer;
      text-align: center;
      border-bottom: 3px solid transparent;
      box-sizing: border-box;
    }

    .activeBox {
      padding: 0 16px;
    }

    .active {
      color: #2d8cf0 !important;
      border-bottom: 3px solid #2d8cf0 !important;
      border-bottom-color: #2d8cf0 !important;
      box-sizing: border-box;

      font-weight: bold;
    }
  }

  .logImage {
    width: 70px;
    height: 26px;
    margin: 0 auto 13px;
  }

  .logbox {
    width: 70px;
    height: 26px;
    margin: 0 auto 13px;
    background-size: 100% 100%;
  }

  .logo {
    min-width: 232px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    // background-color: rgba(255, 255, 255, 0.2);

    .logo-left {
      .error {
        border-radius: 4px;
        background-color: #ffffff;
      }

      .el-image,
      img {
        width: 30px;
        height: 30px;
      }
    }

    .logo-left {
      display: flex;
      box-sizing: border-box;
    }

    .name {
      color: #b6babf;
      font-weight: 600;
      font-family: PingFang SC, -apple-system, BlinkMacSystemFont, Segoe UI,
        Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, apple color emoji,
        segoe ui emoji, Segoe UI Symbol, noto color emoji;
      font-size: 15px;
      padding-left: 10px;
      line-height: 30px;
    }
  }

  // .logo:hover {
  //   background-color: rgba(255, 255, 255, 0.2);
  //   color: #fff !important;
  // }
  // .logo:hover .show-edit {
  //   display: block;
  // }
  .show-edit {
    display: none;
    cursor: pointer;
  }

  .menu-list {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .icon-img {
    width: 20px;
    height: 20px;
    margin-right: 12px;
    cursor: pointer;
  }

  .item-font {
    font-size: 16px;
    padding: 10px 20px;
  }

  .right-setting {
    min-width: 128px;
  }

  .menu-name {
    // color: #8a8c99 !important;
    color: #ffffff !important;
    height: 40px;
    display: flex;
    align-items: center;
    border-bottom: 3px solid transparent;
    font-size: 15px;
    // font-weight: bold;
  }

  .menu-name:hover {
    border-bottom: 3px solid #2d8cf0 !important;
    border-bottom-color: #2d8cf0 !important;
    color: #2d8cf0 !important;
  }
</style>